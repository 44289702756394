import React from 'react';

const Loader = () => {
    return (
        <div className='flex justify-center items-center'>
            <div className='loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4'></div>
        </div>
    );
}

export default Loader;
