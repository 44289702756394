import React from 'react';

const AddCoupan = ({ addcoupon, setaddcoupon }) => {
    return (
        addcoupon === false ? (
            <button
                onClick={() => {
                    setaddcoupon(true)
                }}
                className='flex items-center flex-row-reverse gap-2 text-[#2F54EB] mt-6'
            >
                回数券を追加
                <svg
                    width='22'
                    height='22'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect width='18' height='18' fill='white' />
                    <path
                        d='M12.2344 8.4375H9.5625V5.76562C9.5625 5.68828 9.49922 5.625 9.42188 5.625H8.57812C8.50078 5.625 8.4375 5.68828 8.4375 5.76562V8.4375H5.76562C5.68828 8.4375 5.625 8.50078 5.625 8.57812V9.42188C5.625 9.49922 5.68828 9.5625 5.76562 9.5625H8.4375V12.2344C8.4375 12.3117 8.50078 12.375 8.57812 12.375H9.42188C9.49922 12.375 9.5625 12.3117 9.5625 12.2344V9.5625H12.2344C12.3117 9.5625 12.375 9.49922 12.375 9.42188V8.57812C12.375 8.50078 12.3117 8.4375 12.2344 8.4375Z'
                        fill='#2F54EB'
                    />
                    <path
                        d='M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391Z'
                        fill='#2F54EB'
                    />
                </svg>
            </button>
        ) : (
            <button
                onClick={() => {
                    setaddcoupon(false)
                }}
                className='flex items-center flex-row-reverse gap-2 text-[#F5222D] mt-6'
            >
                回数券を削除
                <svg
                    width='22'
                    height='22'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <rect width='18' height='18' fill='white' />
                    <path
                        d='M12.2344 8.4375H5.76562C5.68828 8.4375 5.625 8.50078 5.625 8.57812V9.42188C5.625 9.49922 5.68828 9.5625 5.76562 9.5625H12.2344C12.3117 9.5625 12.375 9.49922 12.375 9.42188V8.57812C12.375 8.50078 12.3117 8.4375 12.2344 8.4375Z'
                        fill='#F5222D'
                    />
                    <path
                        d='M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM9 15.5391C5.38945 15.5391 2.46094 12.6105 2.46094 9C2.46094 5.38945 5.38945 2.46094 9 2.46094C12.6105 2.46094 15.5391 5.38945 15.5391 9C15.5391 12.6105 12.6105 15.5391 9 15.5391Z'
                        fill='#F5222D'
                    />
                </svg>
            </button>
        )
    );
}

export default AddCoupan;
