/* eslint-disable no-lone-blocks */
import React, { useState, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../context/userContext'

const Login = () => {
  let navigate = useNavigate()
  const auth = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const { setUser } = auth

  const handleSubmit = async (e) => {
    try {
      const res = await axios.post('/user/signin', {
        email,
        password,
      })
      setUser(res.data.user)
      navigate('/user/dashboard', { replace: true })
    } catch (error) {
      setFlashMessage(error.response.data.error)
    }
  }

  const showPassword = async (e) => {
    setPasswordVisible(!passwordVisible)
  }
  return (
    <>
      <div className='mt-5 mx-auto'>
        <div className='mt-6'>
          <div className='flex items-center gap-3 w-full border border-[#ADC6FF] bg-white text-[#141414] rounded-[2px] px-2 '>
            <div>
              <svg
                width='17'
                height='17'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.1889 12.4937C13.8521 11.6958 13.3632 10.9711 12.7497 10.3598C12.1379 9.74679 11.4133 9.25803 10.6157 8.92053C10.6086 8.91695 10.6014 8.91517 10.5943 8.9116C11.7068 8.10803 12.43 6.7991 12.43 5.32231C12.43 2.87588 10.4479 0.893738 8.00144 0.893738C5.55501 0.893738 3.57287 2.87588 3.57287 5.32231C3.57287 6.7991 4.29608 8.10803 5.40858 8.91338C5.40144 8.91695 5.3943 8.91874 5.38715 8.92231C4.58715 9.25981 3.8693 9.74374 3.25323 10.3616C2.64021 10.9733 2.15145 11.698 1.81394 12.4955C1.48237 13.2763 1.30355 14.1135 1.28715 14.9616C1.28668 14.9807 1.29002 14.9996 1.29699 15.0174C1.30395 15.0351 1.3144 15.0513 1.32771 15.0649C1.34103 15.0786 1.35694 15.0894 1.3745 15.0968C1.39207 15.1042 1.41095 15.108 1.43001 15.108H2.50144C2.58001 15.108 2.64251 15.0455 2.6443 14.9687C2.68001 13.5902 3.23358 12.2991 4.21215 11.3205C5.22465 10.308 6.5693 9.75088 8.00144 9.75088C9.43358 9.75088 10.7782 10.308 11.7907 11.3205C12.7693 12.2991 13.3229 13.5902 13.3586 14.9687C13.3604 15.0473 13.4229 15.108 13.5014 15.108H14.5729C14.5919 15.108 14.6108 15.1042 14.6284 15.0968C14.6459 15.0894 14.6619 15.0786 14.6752 15.0649C14.6885 15.0513 14.6989 15.0351 14.7059 15.0174C14.7129 14.9996 14.7162 14.9807 14.7157 14.9616C14.6979 14.108 14.5211 13.2777 14.1889 12.4937ZM8.00144 8.39374C7.1818 8.39374 6.41037 8.0741 5.83001 7.49374C5.24965 6.91338 4.93001 6.14195 4.93001 5.32231C4.93001 4.50267 5.24965 3.73124 5.83001 3.15088C6.41037 2.57052 7.1818 2.25088 8.00144 2.25088C8.82108 2.25088 9.59251 2.57052 10.1729 3.15088C10.7532 3.73124 11.0729 4.50267 11.0729 5.32231C11.0729 6.14195 10.7532 6.91338 10.1729 7.49374C9.59251 8.0741 8.82108 8.39374 8.00144 8.39374Z'
                  fill='#434343'
                />
              </svg>
            </div>
            <input
              type='text'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='メールアドレス'
              className='w-full h-full py-2 placeholder-[#D9D9D9] text-[#141414] outline-none'
            />
          </div>
        </div>

        <div className='mt-6'>
          <div className='flex items-center gap-3 w-full border border-[#ADC6FF] bg-white text-[#141414] rounded-[2px] px-2 '>
            <div>
              <svg
                width='15'
                height='17'
                viewBox='0 0 14 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.7163 7.14808H11.502V3.14808C11.502 1.88558 10.4788 0.862366 9.21631 0.862366H4.78774C3.52524 0.862366 2.50202 1.88558 2.50202 3.14808V7.14808H1.28774C0.971666 7.14808 0.716309 7.40344 0.716309 7.71951V14.5767C0.716309 14.8927 0.971666 15.1481 1.28774 15.1481H12.7163C13.0324 15.1481 13.2877 14.8927 13.2877 14.5767V7.71951C13.2877 7.40344 13.0324 7.14808 12.7163 7.14808ZM3.78774 3.14808C3.78774 2.59629 4.23595 2.14808 4.78774 2.14808H9.21631C9.7681 2.14808 10.2163 2.59629 10.2163 3.14808V7.14808H3.78774V3.14808ZM12.002 13.8624H2.00202V8.4338H12.002V13.8624ZM6.50202 11.3802V12.3267C6.50202 12.4052 6.56631 12.4695 6.64488 12.4695H7.35917C7.43774 12.4695 7.50202 12.4052 7.50202 12.3267V11.3802C7.64943 11.2744 7.75945 11.1245 7.81626 10.9522C7.87306 10.7798 7.87372 10.5939 7.81813 10.4212C7.76254 10.2484 7.65358 10.0978 7.50692 9.99093C7.36027 9.88406 7.18349 9.82648 7.00202 9.82648C6.82056 9.82648 6.64378 9.88406 6.49713 9.99093C6.35047 10.0978 6.24151 10.2484 6.18592 10.4212C6.13033 10.5939 6.13099 10.7798 6.18779 10.9522C6.2446 11.1245 6.35462 11.2744 6.50202 11.3802V11.3802Z'
                  fill='#434343'
                />
              </svg>
            </div>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type={passwordVisible ? 'text' : 'password'}
              placeholder='パスワード'
              className='w-full h-full py-2 placeholder-[#D9D9D9] text-[#141414] outline-none'
            />
            <button
              onClick={() => showPassword()}
            >
              {passwordVisible ? (
                <svg
                  width='18'
                  height='14'
                  viewBox='0 0 16 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15.6822 5.5404C13.9894 1.97433 11.4305 0.179688 8.0001 0.179688C4.56796 0.179688 2.01082 1.97433 0.31796 5.54219C0.250059 5.68597 0.214844 5.843 0.214844 6.00201C0.214844 6.16102 0.250059 6.31805 0.31796 6.46183C2.01082 10.0279 4.56975 11.8225 8.0001 11.8225C11.4322 11.8225 13.9894 10.0279 15.6822 6.46005C15.8197 6.17076 15.8197 5.83505 15.6822 5.5404ZM8.0001 10.5368C5.11975 10.5368 3.01082 9.07612 1.52332 6.00112C3.01082 2.92612 5.11975 1.4654 8.0001 1.4654C10.8805 1.4654 12.9894 2.92612 14.4769 6.00112C12.9912 9.07612 10.8822 10.5368 8.0001 10.5368ZM7.92867 2.85826C6.19296 2.85826 4.78582 4.2654 4.78582 6.00112C4.78582 7.73683 6.19296 9.14397 7.92867 9.14397C9.66439 9.14397 11.0715 7.73683 11.0715 6.00112C11.0715 4.2654 9.66439 2.85826 7.92867 2.85826ZM7.92867 8.00112C6.82332 8.00112 5.92867 7.10647 5.92867 6.00112C5.92867 4.89576 6.82332 4.00112 7.92867 4.00112C9.03403 4.00112 9.92868 4.89576 9.92868 6.00112C9.92868 7.10647 9.03403 8.00112 7.92867 8.00112Z'
                    fill='#2F54EB'
                  />
                </svg>
              ) : (
                // eyes off svg
                <svg
                  width='18'
                  height='14'
                  viewBox='0 0 16 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" fill='#2F54EB' />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" fill='#2F54EB' />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" fill='#2F54EB' />
                </svg>
              )}
            </button>
          </div>
        </div>

        <div className='flex items-center gap-2 mt-4'>
          <input
            type='checkbox'
            name='checkbox'
            id='checkbox'
            className='w-4 h-4 cursor-pointer'
            onClick={() => setRememberMe(!rememberMe)}
          />
          <label htmlFor='checkbox' className='text-[#141414] cursor-pointer'>
            ログイン状態を保持
          </label>
        </div>

        <button
          onClick={() => handleSubmit()}
          className={`w-full max-w-[150px] flex items-center justify-center mx-auto 
                            ${!email || !password ? 'bg-[#D9D9D9]' : 'bg-[#2F54EB]'}
                            py-2.5 mt-8 
                            ${!email || !password ? 'text-[#8C8C8C]' : 'text-white'}
                            ${!email || !password ? 'cursor-not-allowed' : 'cursor-pointer'} 
                            rounded-[2px]`}
        >
          ログイン
        </button>
        <Link
          to='/forgot-password'
          className='flex items-center justify-center mt-10'>
          <p className='text-[#2F54EB] text-sm'>パスワードを忘れた場合</p>
        </Link>
        <div className='flex items-center justify-center mt-5'>
          {flashMessage && (
            <p className='text-[#FF4D4F] text-sm'>{flashMessage}</p>
          )}
        </div>
      </div>
    </>
  )
}

export default Login
