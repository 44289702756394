import { Table } from 'antd';
import moment from 'moment';
const { Column } = Table;
const UserCalenderStatus = ({ histories }) => {
    return (
        <Table
            dataSource={histories}
            >
            <Column
                title='登録日'
                dataIndex='date'
                render={(text) => {
                    return moment(text).format('YYYY/MM/DD');
                }}
                sorter={(a, b) => a.age - b.age}
            />
            <Column
                title='種類'
                dataIndex='type'
                sorter={(a, b) => a.age - b.age}
            />
            <Column
                title='詳細'
                dataIndex='body'
            />
            </Table>
    )
};
export default UserCalenderStatus;