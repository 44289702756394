import { useState, useContext, useCallback, useEffect } from 'react'
import Header from '../user-components/Header'
import CalendarWithButton from '../user-components/Calendar-With-Button'
import { UserContext } from '../context/userContext'
import moment from 'moment'
import Toast from '../utils/Toast'
import axios from 'axios'
import CalendarFull from '../user-components/Calendar-Full'
import { Link } from 'react-router-dom'
import CalendarDisable from '../user-components/CalendarDisable'

const Dashboard = () => {
  const auth = useContext(UserContext)
  const [noBooking] = useState(false)
  const [confirmation, setconfirmation] = useState(false)
  const { user, setUser, getCalendar } = auth
  const [flashActive, setFlashActive] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [flashType, setFlashType] = useState('')
  const [timer] = useState(1000);
  const [calendar, setCalendar] = useState(null)
  const [date, setdate] = useState()
  const [time, settime] = useState()
  const [day, setday] = useState()
  const [finish, setfinish] = useState(false)
  const [bookingId, setbookingId] = useState('')
  const [ticketCancel, setticketCancel] = useState(false)
  const [handleBookingSlot, sethandleBookingSlot] = useState(false)
  const [cancelBookingView, setcancelBookingView] = useState(false)
  const [cancelBookingModal, setcancelBookingModal] = useState(false)

  const hideToast = () => {
    setFlashActive(false);
  };

  const handleShowToast = useCallback(() => {
    hideToast();
    if (flashMessage) {
      setFlashActive(true);
      window.setTimeout(hideToast, timer);
    }
  }, [flashMessage, timer]);

  const getTodayCalendar = useCallback(async () => {
    try {
      const res = await axios.get('/user/day-calendar/0')
      const newCalendar = res.data.dayCalendar
      const currentTime = moment().format('HH:mm')
      const newTimeSlots = newCalendar?.timeSlots?.map((timeSlot) => {
        if (timeSlot?.defaultSeats === 0) {
          return null
        }
        if (timeSlot?.start_time > currentTime) {
          return timeSlot
        }
        return null
      })
      if (newTimeSlots?.some((timeSlot) => timeSlot)) {
        newCalendar.timeSlots = newTimeSlots.filter((timeSlot) => timeSlot)
        setCalendar(newCalendar)
      }
    } catch (error) {
      setFlashType('error')
      handleShowToast()
    }
  }, [handleShowToast])

  const finishBooking = useCallback(async () => {
    try {
      const res = await axios.get('/user/finish-bookings')
      setUser(res.data.user)
    } catch (error) {
      window.location.href = '/'
      setFlashType('error')
      handleShowToast()
    }
  }, [handleShowToast, setUser])

  const handleBooking = async () => {
    try {
      const { data } = await axios.post('/user/bookings', {
        date: date,
        time: time,
      })
      setUser(data.user)
      setfinish(true)
      setconfirmation(false)
    } catch (error) {
      if (error.response.data.error) {
        setFlashMessage('すでに予約済みです')
        setFlashType('error')
        handleShowToast()
      }
    }
  }

  useEffect(() => {
    finishBooking()
  }, [finishBooking])

  useEffect(() => {
    getTodayCalendar()
  }, [getCalendar, getTodayCalendar, handleShowToast, user?.bookings?.length, user?.confirmation])

  const japaneseDay = {
    "Monday": "月",
    "Tuesday": "火",
    "Wednesday": "水",
    "Thursday": "木",
    "Friday": "金",
    "Saturday": "土",
    "Sunday": "日",
  }

  const cancelBooking = async () => {
    try {
      const { data } = await axios.delete(`/user/bookings/${bookingId}`)
      setUser(data.user)
      setconfirmation(false)
      setbookingId('')
      setcancelBookingModal(false)
      setcancelBookingView(true)
    } catch (error) {
      console.warn(error.response.data.error)
      setFlashType('error')
      handleShowToast()
    }
  }

  const cancelTicket = async () => {
    try {
      const { data } = await axios.delete(`/user/tickets/${bookingId}`)
      setUser(data.user)
      setconfirmation(false)
      setticketCancel(false)
      setcancelBookingModal(false)
      setcancelBookingView(true)
    } catch (error) {
      console.warn(error.response.data.error)
      setFlashType('error')
      handleShowToast()
    }
  }

  const setConfirmationDate = (newday, date, newTime, id, text) => {
    if (text === 'ticket') {
      setticketCancel(true)
    }
    setday(newday)
    setdate(date)
    settime(newTime)
    setbookingId(id)
    if (text === 'booking') {
      sethandleBookingSlot(true)
      setconfirmation(null)
    } else {
      setconfirmation(true)
    }
  }

  const handleCancelBooking = () => {
    if (ticketCancel) {
      cancelTicket()
    } else {
      cancelBooking()
    }
  }

  const checUserBooking = () => {
    if (user?.memberShipType === '月12回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 3) {
        return false
      }
    } else if (user?.memberShipType === '月8回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
        return false
      }
    } else if (user?.memberShipType === '月4回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
        return false
      }
    } else if (user?.memberShipType === '回数券会員') {
      if (user?.ticketDetails?.length === 1) {
        return false
      }
    }
    return true
  }

  const checkIfUserBookedThatDay = (day) => {
    if (user?.bookings?.some((booking) => booking?.date === day)) {
      return true
    } else if (user?.ticketDetails?.some((ticket) => ticket?.date === day)) {
      console.log('ticket')
      return true
    }
    return false
  }

  const checkIfUserIsInTheClass = (day, time) => {
    if (user?.bookings?.some((booking) => booking?.date === day && booking?.time === time)) {
      return true
    } else if (user?.ticketDetails?.some((ticket) => ticket?.date === day && ticket?.time === time)) {
      return true
    }
    return false
  }

  const checkCurrentDateAndTime = (day, time) => {
    const currentTime = moment().format('HH:mm')
    const currentDate = moment().format('YYYY-MM-DD')
    const constructDate = moment(day, 'YYYY年MM月DD日').format('YYYY-MM-DD')
    if (constructDate === currentDate && time < currentTime) {
      return true
    }
    return false
  }

  const checkIfUserHasTotalBookingsOrTicketCountLeft = () => {
    if (user?.bookings || user?.ticketCount) {
      if (parseInt(user?.totalBookings) + parseInt(user?.ticketCount) > 0) {
        return true
      }
      if (parseInt(user?.ticketCount) > 0) {
        return true
      }
      if (parseInt(user?.totalBookings) > 0) {
        return true
      }
      return false
    }
  }

  return (
    <div className='min-h-[calc(100vh-200px)] overflow-auto mb-10'>
      <Header noBooking={noBooking} type='dashboard' />
      {flashActive && (
        <Toast
          type={flashType}
          message={flashMessage}
          duration={3000}
          active={flashActive}
          setActive={setFlashActive}
          position={'tright'}
          width={'small'}
        />
      )}
      {cancelBookingView === false ? (
        finish === false ? (
          confirmation === false ? (
            <div className='p-5 w-full h-[calc(100vh-74px)] overflow-auto'>
              <div className='bg-white p-5'>
                <div className='w-full text-[#434343] border-b border-[#F0F0F0] pb-4 mb-6 text-xl'>
                  {user?.kanjiName}さん　の予約状況
                </div>
                {noBooking === true ? (
                  <div className='text-[#F5222D] mt-6'>
                    現在仮登録なため、ご予約ができません。
                    <br />
                    オーナーが許可する次第、正式に登録が完了いたします。
                  </div>
                ) : (
                  <div>
                    <div className='text-[#8C8C8C] text-sm mb-1.5'>会員タイプ</div>
                    <div className='text-[#141414] font-bold text-[24px]'>
                      {user?.memberShipType}
                    </div>
                    <div className='flex gap-12 mt-6'>
                      {typeof user?.totalBookings === 'number' && (
                        <div>
                          <div className='text-[#8C8C8C] text-sm mb-1.5'>
                            今月の残りの予約可能回数
                          </div>
                          <div className='text-[#141414] font-bold text-[24px]'>
                            {user?.totalBookings}回
                          </div>
                        </div>
                      )}

                      <div>
                        <div className='text-[#8C8C8C] text-sm mb-1.5'>回数券</div>
                        <div className='text-[#141414] font-bold text-[24px]'>
                          {user?.ticketCount}回
                          {user?.ticketCount > 0 && (' (' + moment(user?.ticketExpiration).format('YYYY/MM/DD') + 'まで有効)')}
                        </div>
                      </div>
                    </div>

                    <div className='mt-6'>
                      {user?.bookings?.length > 0 && (
                        user?.bookings.map((booking, index) => {
                          return (
                            <div className='mt-6' key={index}>
                              <div className='text-[#8C8C8C] text-sm mb-1.5'>
                                次のレッスンは
                              </div>
                              <div className='flex sm:items-center flex-col sm:flex-row gap-5 sm:gap-10'>
                                <div className='text-[#141414] font-bold text-[24px]'>
                                  {booking.date.split('年')[1] + ' '}
                                  {'(' + japaneseDay[moment(booking.date, 'YYYY年MM月DD日').format('dddd')] + ')'}
                                  {' ' + booking.time}
                                  -
                                  {moment(booking.time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}
                                </div>
                                {!checkCurrentDateAndTime(booking.date, booking.time) && (
                                  <button
                                    onClick={() => setConfirmationDate(japaneseDay[moment(booking.date, 'YYYY年MM月DD日').format('dddd')], booking.date, booking.time, booking._id, 'book')}
                                    className='px-7 w-fit sm:w-auto bg-white py-2.5 border-2 border-[#2F54EB] text-[#2F54EB] rounded-[4px]'>
                                    予約をキャンセル
                                  </button>
                                )}
                              </div>
                              {moment(booking.date, 'YYYY年MM月DD日').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && (
                                <div className='text-[#F5222D] mt-2'>
                                  キャンセルはレッスン開始1時間前までに行ってください。
                                  （それ以降にキャンセルを行うと1回分の予約が消費されます。）
                                </div>
                              )}
                            </div>
                          )
                        })
                      )}
                      {user?.ticketDetails?.length > 0 && (
                        user?.ticketDetails?.map((booking, index) => {
                          return (
                            <div className='mt-6' key={index}>
                              <div className='text-[#8C8C8C] text-sm mb-1.5'>
                                次のレッスンは
                              </div>
                              <div className='flex sm:items-center flex-col sm:flex-row gap-5 sm:gap-10'>
                                <div className='text-[#141414] font-bold text-[24px]'>
                                  {booking.date.split('年')[1] + ' '}
                                  {'(' + japaneseDay[moment(booking.date, 'YYYY年MM月DD日').format('dddd')] + ')'}
                                  {' ' + booking.time}
                                  -
                                  {moment(booking.time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}
                                </div>
                                {!checkCurrentDateAndTime(booking.date, booking.time) && (
                                  <button
                                    onClick={() => setConfirmationDate(japaneseDay[moment(booking.date, 'YYYY年MM月DD日').format('dddd')], booking.date, booking.time, booking._id, 'ticket')}
                                    className='px-7 w-fit sm:w-auto bg-white py-2.5 border-2 border-[#2F54EB] text-[#2F54EB] rounded-[4px]'>
                                    予約をキャンセル
                                  </button>
                                )}
                              </div>
                              {moment(booking.date, 'YYYY年MM月DD日').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && (
                                <div className='text-[#F5222D] mt-2'>
                                  キャンセルはレッスン開始1時間前までに行ってください。
                                  （それ以降にキャンセルを行うと1回分の予約が消費されます。）
                                </div>
                              )}
                            </div>
                          )
                        })
                      )}
                      {checkIfUserHasTotalBookingsOrTicketCountLeft() && (user?.bookings?.length === 0 && user?.ticketDetails?.length === 0) && (
                        <div className='flex flex-col'>
                          <div className='text-[#8C8C8C] text-[14px]'>
                            次のレッスンは
                          </div>
                          <div className='text-[#141414] text-[24px] font-bold mt-2'>
                            ご予約がありません
                          </div>
                          <Link
                            to='/user/calendar'
                            className='text-[#2F54EB] text-[16px] mt-4'>
                            カレンダー予約へ
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className='bg-white p-5 mt-5'>
                <div className='w-full text-[#434343] border-b border-[#F0F0F0] pb-4 mb-6'>
                  本日　{calendar?.singlejapaneseday}{calendar?.japaneseday}　の空き状況
                </div>
                <div className='text-[#F5222D] mt-6'>
                  {user?.memberShipType !== '月12回会員会員' && (
                    (user?.bookings.length > 0 || user?.ticketCount > 0) && (
                      user?.bookings.length > 0 ? (user?.bookings.map((booking, index) => {
                        return (
                          <div key={index}>
                            {booking.date === calendar?.singlejapaneseday && (
                              <div>
                                レッスンをキャンセルまた完了しない時は、新しいレッスンを予約できません。
                              </div>
                            )}
                          </div>
                        )
                      }
                      )) : user?.ticketDetails?.map((booking, index) => {
                        return (
                          <div key={index}>
                            {booking.date === calendar?.singlejapaneseday && (
                              <div>
                                レッスンをキャンセルまた完了しない時は、新しいレッスンを予約できません。
                              </div>
                            )}
                          </div>
                        )
                      })
                    ))}
                  {(user?.memberShipType === '月12回会員会員') && (user?.bookings?.length >= 3) && (
                    <div className='text-[#FF4D4F]'>レッスンをキャンセルまた完了しない時は、新しいレッスンを予約できません。</div>
                  )}
                </div>

                <div className='grid grid-cols-1 2sm:grid-cols-3 sm:grid-cols-4 2md:grid-cols-5 lg:grid-cols-6 w-full mt-5 mb-10'>
                  {calendar?.timeSlots?.map((day, index) => {
                    return (
                      <div key={index}>
                        {
                          moment(day?.start_time, 'HH:mm').format('HH:mm') >= moment().format('HH:mm') && (
                            (day?.defaultSeats === 0 || day?.defaultClose) ? (
                              <></>
                            ) :
                              (day?.defaultSeats === day?.members?.length) ? (
                                <CalendarFull start_time={day?.start_time} day={day} key={index} totalPeople={day?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 && user?.bookings} userTickets={user?.ticketDetails?.length > 0 && user?.ticketDetails} />
                              ) : !checUserBooking() || checkIfUserBookedThatDay(calendar?.singlejapaneseday) || checkIfUserIsInTheClass(calendar?.singlejapaneseday, day?.start_time) || !checkIfUserHasTotalBookingsOrTicketCountLeft() ? (
                                <CalendarDisable start_time={day?.start_time} day={calendar} key={index} totalPeople={day?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 && user?.bookings} userTickets={user?.ticketDetails?.length > 0 && user?.ticketDetails} />
                              ) :
                                (
                                  <CalendarWithButton start_time={day?.start_time} day={calendar} key={index} totalPeople={day?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 && user?.bookings} userTickets={user?.ticketDetails?.length > 0 && user?.ticketDetails} />
                                )
                          )
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            </div >
          ) : (confirmation === true) ? (
            <div className='p-5'>
              <button
                onClick={() => {
                  setconfirmation(false)
                }}
                className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse mb-6'
              >
                戻る
                <svg
                  width='10'
                  height='15'
                  viewBox='0 0 7 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                    fill='#2F54EB'
                  />
                </svg>
              </button>
              <div className='bg-white p-5'>
                <div className='w-full text-[#434343] border-b border-[#F0F0F0] pb-4 mb-6 text-xl'>
                  予約のキャンセル
                </div>
                <div className='text-[#434343] mt-6'>
                  下記のご予約をキャンセルしてもよろしいですか？
                </div>

                <div className='mt-10'>
                  <div className='text-[#8C8C8C] text-sm mb-1'>日時</div>
                  <div className='flex items-center gap-5 sm:gap-10'>
                    <div className='text-[#141414] font-bold text-[24px]'>
                      {date.split('年')[1]} {date.split('年')[2]}
                      {'(' + day + ')'}
                      {' ' + time} -
                      {moment(time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}
                    </div>
                  </div>
                </div>
                {moment(date.split('年').join('-').split('月').join('-').split('日').join('')).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && (
                  <div className='text-[#F5222D] mt-5'>
                    キャンセルはレッスン開始1時間前までに行ってください。
                    （それ以降にキャンセルを行うと1回分の予約が消費されます。）
                  </div>
                )}

                <div className='flex items-center flex-wrap gap-3 mt-6'>
                  <button
                    onClick={() => {
                      setconfirmation(false)
                    }}
                    className='text-[#2F54EB] px-5 py-1.5 border-2 border-[#2F54EB]'
                  >
                    いいえ
                  </button>
                  <button
                    onClick={() => {
                      setcancelBookingModal(true)
                    }}
                    className='text-white px-5 py-1.5 border-2 border-[#2F54EB] bg-[#2F54EB]'>
                    予約をキャンセル
                  </button>
                </div>
              </div>
            </div>
          ) : (handleBookingSlot === true) ? (
            <div className='p-5'>
              <button
                onClick={() => {
                  setconfirmation(false)
                }}
                className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse mb-6'
              >
                戻る
                <svg
                  width='10'
                  height='15'
                  viewBox='0 0 7 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                    fill='#2F54EB'
                  />
                </svg>
              </button>
              <div className='bg-white p-5'>
                <div className='w-full text-[#434343] border-b border-[#F0F0F0] pb-4 mb-6'>
                  予約の確認
                </div>
                <div className='text-[#434343] mt-6'>
                  下記のご予約を確定してもよろしいですか
                </div>

                <div className='mt-10'>
                  <div className='text-[#8C8C8C] text-sm mb-1'>日時</div>
                  <div className='flex items-center gap-5 sm:gap-10'>
                    <div className='text-[#141414] font-bold text-[24px]'>
                      {date.split('年')[1]} {date.split('年')[2]}
                      {day}
                      {' ' + time} -
                      {moment(time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}
                    </div>
                  </div>
                </div>

                <div className='flex items-center flex-wrap gap-3 mt-6'>
                  <button
                    onClick={() => {
                      setconfirmation(false)
                    }}
                    className='text-[#2F54EB] px-5 py-1.5 border-2 border-[#2F54EB]'
                  >
                    いいえ
                  </button>
                  <button
                    onClick={() => {
                      handleBooking()
                    }}
                    className='text-white px-5 py-1.5 border-2 border-[#2F54EB] bg-[#2F54EB]'
                  >
                    予約する
                  </button>
                </div>
              </div>
            </div>
          ) : null
        ) : (
          <>
            <div className='min-h-[calc(100vh-150px)] max-w-[300px] text-center flex items-center justify-center flex-col mx-auto mb-24'>
              <div>
                <svg
                  width='70'
                  height='70'
                  viewBox='0 0 70 70'
                  fill='none'
                  className='mx-auto mb-5'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.131 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4688 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1562 50.1172 23.5703Z'
                    fill='#52C41A'
                  />
                </svg>
                <div className='text-[#141414]'>予約が完了いたしました</div>
              </div>

              <button
                onClick={() => {
                  setconfirmation(false)
                  setfinish(false)
                }}
                className='w-full max-w-[150px] flex items-center justify-center mx-auto bg-white py-2.5 mt-16 border border-[#2F54EB] text-[#2F54EB] rounded-[2px]'
              >
                マイページへ
              </button>
            </div>

            <div className='absolute bottom-3 left-0 w-full flex items-center justify-center flex-col gap-2 px-5'>

              <div className='text-center text-[#434343]'>
                Copyright (C) DTS KICKBOXING GYM
                {' ' + new Date().getFullYear() + ' '}
                All Rights Reserved
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <div className='min-h-[calc(100vh-150px)] max-w-[300px] text-center flex items-center justify-center flex-col mx-auto mb-24'>
            <div>
              <svg
                width='70'
                height='70'
                viewBox='0 0 70 70'
                fill='none'
                className='mx-auto mb-5'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d="M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM37.5 51.875C37.5 52.2188 37.2188 52.5 36.875 52.5H33.125C32.7812 52.5 32.5 52.2188 32.5 51.875V30.625C32.5 30.2812 32.7812 30 33.125 30H36.875C37.2188 30 37.5 30.2812 37.5 30.625V51.875ZM35 25C34.0187 24.98 33.0843 24.5761 32.3974 23.875C31.7105 23.1739 31.3258 22.2315 31.3258 21.25C31.3258 20.2685 31.7105 19.3261 32.3974 18.625C33.0843 17.9239 34.0187 17.52 35 17.5C35.9813 17.52 36.9157 17.9239 37.6026 18.625C38.2895 19.3261 38.6742 20.2685 38.6742 21.25C38.6742 22.2315 38.2895 23.1739 37.6026 23.875C36.9157 24.5761 35.9813 24.98 35 25Z"
                  fill="#2F54EB"
                />
              </svg>
              <div className='text-[#141414]'>予約をキャンセルしました</div>
            </div>

            <div
              onClick={() => {
                setcancelBookingModal(false)
                setcancelBookingView(false)
              }}
              className='w-full max-w-[150px] flex items-center justify-center mx-auto bg-white py-2.5 mt-16 border border-[#2F54EB] text-[#2F54EB] rounded-[2px] cursor-pointer'
            >
              マイページへ
            </div>
          </div>
        </>
      )}
      {cancelBookingModal && (
        <>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-5 max-w-[400px] w-[calc(100%-40px)] rounded-[4px] z-[100] transition ${cancelBookingModal === true ? 'visible opacity-100' : 'invisible opacity-0'
              }`}
          >
            <div>
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_170_12581)'>
                  <path
                    d='M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z'
                    fill='#FAAD14'
                  />
                  <path
                    d='M9.82104 15.3214C9.82104 15.634 9.94522 15.9338 10.1662 16.1548C10.3873 16.3758 10.687 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.687 14.1429 10.3873 14.267 10.1662 14.4881C9.94522 14.7091 9.82104 15.0089 9.82104 15.3214ZM10.4103 12.5714H11.5889C11.6969 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.6969 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z'
                    fill='#FAAD14'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_170_12581'>
                    <rect width='22' height='22' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='text-[#434343] font-[500] mb-2.5 mt-4'>
              予約のキャンセル
            </div>
            <div className='text-[#141414]'>
              {date.split('年')[1]} {date.split('年')[2]} {'(' + day + ')'}　{time} -
              {moment(time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}　のご予約をキャンセルしてもよろしいですか？
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 items-center gap-3 mt-6 max-w-[300px] mx-auto'>
              <button
                onClick={() => {
                  setcancelBookingModal(false)
                }}
                className='text-[#2F54EB] border-2 border-[#2F54EB] px-5 py-2 rounded-[4px]'
              >
                いいえ
              </button>
              <button
                onClick={() => {
                  handleCancelBooking()
                }}
                className='text-[white] bg-[#2F54EB] border-2 border-[#2F54EB] px-1.5 py-2 rounded-[4px]'
              >
                予約をキャンセル
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard
