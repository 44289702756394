import { useState } from 'react'
import Login from '../user-components/Login'
import RegisterComp from '../user-components/Register'

const Register = () => {
  const [login, setlogin] = useState(true)
  const [userRegister, setuserRegister] = useState(false)
  const [userRegiserView, setuserRegiserView] = useState(false)

  return (
    <>
      <div className='pt-20 h-full max-h-screen overflow-y-auto p-5 flex items-center justify-center'>
        {userRegiserView === false ? (
          <div className='max-w-[300px] mx-auto mt-10 mb-24'>
            {userRegister === false ? (
              <div>
                <div className='mx-auto w-full flex items-center justify-center'>
                  <img src='/images/logo.svg' alt='logo' />
                </div>

                <div className='flex gap-5 mt-10'>
                  <button
                    onClick={() => {
                      setlogin(true)
                    }}
                    className={`pb-2 ${login === true
                      ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                      : 'text-[#141414]'
                      }`}
                  >
                    ログイン
                  </button>
                  <button
                    onClick={() => {
                      setlogin(false)
                    }}
                    className={`pb-2 ${login === false
                      ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                      : 'text-[#141414]'
                      }`}
                  >
                    会員登録
                  </button>
                </div>
              </div>
            ) : (
              <div className='mt-5 flex-col mx-auto'></div>
            )}
            {login === true ? (
              <Login />
            ) : (
              <RegisterComp
                userRegister={userRegister}
                setuserRegister={setuserRegister}
                setlogin={setlogin}
                setuserRegiserView={setuserRegiserView}
              />
            )}
          </div>
        ) : (
          <>
            <div className='min-h-[calc(100vh-150px)] w-full text-center flex items-center justify-center flex-col mx-auto mb-24'>
              <div>
                <svg
                  width='70'
                  height='70'
                  viewBox='0 0 70 70'
                  fill='none'
                  className='mx-auto mb-5'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d="M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.1309 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4687 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1563 50.1172 23.5703V23.5703Z"
                    fill="#52C41A"
                  />
                </svg>
                <div className='text-[#141414] text-[20px] mt-4'>オーナーが登録を許可するまでお待ち下さい</div>
                <div className='text-[#434343] text-[16px] mt-6'>オーナーが許可したら、ご登録いただいたメールアドレスに確認メールを送信します。</div>
              </div>
            </div>
          </>
        )}

        <div className='absolute bottom-3 left-0 w-full flex items-center justify-end flex-col gap-2 px-5'>

          <div className='text-center text-[#434343]'>
            Copyright (C) DTS KICKBOXING GYM
            {' ' + new Date().getFullYear() + ' '}
            All Rights Reserved
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
