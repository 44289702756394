import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from '../admin-components/Header';
import CalendarEdit from '../admin-components/Calendar-Edit';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../admin-components/BackButton';
import Toast from '../utils/Toast'
import Modal from '../admin-components/Modal';
import moment from 'moment';
import Loader from '../admin-components/Loader';
import DeleteModal from '../admin-components/DeleteModal';
import EditCalendarDropdown from '../admin-components/Edit-Calendar-Dropdown';

const CalenderEdit = () => {
    const navigate = useNavigate();
    const [calenderData, setcalenderData] = useState(null);
    const { pathname } = useLocation();
    const location = useLocation();
    const flashActive = useRef(false)
    const flashMessage = useRef('')
    const flashType = useRef('')
    const [showToast, setShowToast] = useState(false);
    const [updateModal, setUpdateModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [timer] = useState(4000);
    const [users, setUsers] = useState([]);
    const [removedusers, setRemovedusers] = useState([]);
    const [canceledSlots, setCanceledSlots] = useState([]);
    const [cencelSlotModal, setCencelSlotModal] = useState(false);
    const [openSlotModal, setOpenSlotModal] = useState(false);
    const [isClose, setIsClose] = useState(true);
    const [ordinaryModal, setOrdinaryModal] = useState(false);
    const [dropdown, setdropdown] = useState(false);
    const [dropdownValue, setdropdownValue] = useState();
    const [openCalendar, setOpenCalendar] = useState('')
    // const [currentDay, setCurrentDay] = useState(moment().format('YYYY-MM-DD'));
    const [updateTimeSlotsModal, setUpdateTimeSlotsModal] = useState(false);
    const [usersToBeAdded, setUsersToBeAdded] = useState([]);
    const [usersToBeRemoved, setUsersToBeRemoved] = useState([]);

    const hideToast = useCallback(() => {
        flashActive.current = false;
        setShowToast(false);
    }, []);

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        try {
            const res = await axios.get("/admin/users");
            setUsers(res.data.users);
        } catch (err) {
            window.location.href = "/admin/login";
            return 0;
        }
    }

    const handleShowToast = useCallback(() => {
        hideToast();
        if (flashMessage.current) {
            flashActive.current = true;
            setShowToast(true);
            window.setTimeout(hideToast, timer);
        }
    }, [flashMessage, hideToast, timer]);

    const getCurrentDateDetails = useCallback(async () => {
        try {
            const match = matchPath({
                path: '/admin/calendar-edit/:id',
                exact: true,
                strict: false,
            }, pathname);
            if (match) {
                const { id } = match.params;
                const { data } = await axios.get(`/calendar-by-date/${id}`);
                setcalenderData(data.calendar);
            }
        } catch (error) {
            console.log(error);
        }
    }, [pathname]);

    useEffect(() => {
        getCurrentDateDetails();
    }, [getCurrentDateDetails]);

    const toggleCalendar = (date, text) => {
        if (calenderData?.date < moment().format('YYYY-MM-DD')) {
            return
        }
        setdropdownValue({
            text,
            date
        })
        setUpdateTimeSlotsModal(true)
    }

    const updateTimeSlot = useCallback(async (date, text) => {
        try {
            setLoading(true)

            const { data } = await axios.put('/update-single-timeSlot', {
                date: new Date(dropdownValue.date),
                day: dropdownValue.text,
            });
            setcalenderData(data.calendar);
            setUpdateTimeSlotsModal(false)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }, [dropdownValue])

    const handleToggle = () => {
        if (calenderData?.date < moment().format('YYYY-MM-DD')) {
            flashMessage.current = '以前のカレンダーは編集できません。'
            flashType.current = 'error'
            handleShowToast()
        } else {
            setcalenderData({ ...calenderData, isOpen: !calenderData.isOpen });
            setIsClose(!calenderData.isOpen);
            setRemovedusers([])
            setCanceledSlots([])
        }
    };

    const addBooking = useCallback(async (date, time, email) => {
        try {
            const res = await axios.post('/admin/add-booking', {
                date,
                time,
                email,
            });
            let id
            if (res?.data?.isTicket) {
                id = res?.data?.user?.ticketDetails[res?.data?.user?.ticketDetails?.length - 1]._id
            } else {
                id = res?.data?.user?.bookings[res?.data?.user?.bookings?.length - 1]._id
            }
            const newMembers = calenderData.timeSlots.map((newTime) => {
                if (newTime.start_time === time) {
                    const newUser = users.find((user) => user.email === email);
                    const userObject = {
                        email: newUser.email,
                        name: newUser.kanjiName,
                        key: newUser.key,
                        _id: id,
                    }
                    return {
                        ...newTime,
                        members: [...newTime.members, userObject],
                    }
                }
                return newTime;
            }
            );
            setcalenderData({
                ...calenderData,
                timeSlots: newMembers,
            });
            setRemovedusers([])
            setCanceledSlots([])
            getAllUsers();
            getCurrentDateDetails();
        } catch (error) {
            if (error.response.data.error) {
                flashMessage.current = error.response.data.error
                flashType.current = 'error'
                handleShowToast()
            }
        }
    }, [calenderData, getCurrentDateDetails, handleShowToast, users]);

    const removeBooking = useCallback(async (bookingId, email, time) => {
        try {
            const newMembers = calenderData.timeSlots.map((newTime) => {
                if (newTime.start_time === time) {
                    return {
                        ...newTime,
                        members: newTime.members.filter((member) => member.email !== email),
                    }
                }
                return newTime;
            }
            );
            const removedUser = users.find((user) => user.email === email);
            const userObject = {
                email: removedUser.email,
                name: removedUser.kanjiName,
                key: removedUser.key,
            }
            setRemovedusers([...removedusers, userObject])
            setcalenderData({
                ...calenderData,
                timeSlots: newMembers,
            });
            await axios.delete(`/admin/delete-booking/${bookingId}/${email}`);
            getAllUsers();
            getCurrentDateDetails();
        } catch (error) {
            if (error.response.data.error) {
                flashMessage.current = error.response.data.error
                flashType.current = 'error'
                handleShowToast()
            }
        }
    }, [calenderData, getCurrentDateDetails, handleShowToast, removedusers, users]);

    const updateCalendar = async () => {
        try {
            setLoading(true);
            if (usersToBeAdded.length > 0) {
                await axios.put('/admin/book-multiple-users', {
                    users: usersToBeAdded
                });
            }
            if (usersToBeRemoved.length > 0) {
                await axios.put('/admin/remove-multiple-bookings', {
                    users: usersToBeRemoved
                });
            }
            const calendar = [calenderData]
            const { data } = await axios.put('/calendar', {
                data: calendar,
            })
            if (data.message) {
                flashMessage.current = '変更を保存しました。'
                flashType.current = 'success'
                handleShowToast()
                const match = matchPath({
                    path: '/admin/calendar-edit/:id',
                    exact: true,
                    strict: false,
                }, pathname);
                if (match) {
                    const { id } = match.params;
                    const { data } = await axios.get(`/calendar-by-date/${id}`);
                    setcalenderData(data.calendar);
                }
                getAllUsers();
                setUpdateModal(false)
                setCencelSlotModal(false)
                setOpenSlotModal(false)
                setOrdinaryModal(false)
                setRemovedusers([])
                setCanceledSlots([])
                setUsersToBeAdded([])
                setUsersToBeRemoved([])
            }
            setLoading(false);
        } catch (error) {
            if (error.response.data.error) {
                flashMessage.current = error.response.data.error
                flashType.current = 'error'
                handleShowToast()
                setLoading(false);
            }
        }
    };

    const removeAllState = () => {
        setRemovedusers([])
        setCanceledSlots([])
        setUsersToBeAdded([])
        setUsersToBeRemoved([])
    }

    const fixAllTimeSlots = async () => {
        getCurrentDateDetails()
        removeAllState()
    }

    const checkIfPreviousDate = (date) => {
        if (date < moment().format('YYYY-MM-DD')) {
            return true
        }
        return false
    }

    const toastError = (error, type) => {
        flashMessage.current = error
        flashType.current = type
        handleShowToast()
    }
    return (
        <div className='w-full h-screen'>
            <Header type='calendar' />
            {showToast && (
                <Toast
                    type={flashType.current}
                    message={flashMessage.current}
                    duration={10000}
                    active={flashActive.current}
                    setActive={flashActive.current}
                    position={'tcenter'}
                    width={'small'}
                />
            )}
            {loading ? (
                <div className='w-full h-screen flex justify-center items-center'>
                    <Loader />
                </div>
            ) : (
                <div className='w-full h-screen'>
                    <div className='p-5'>
                        <BackButton onClick={() => {
                            navigate('/admin/dashboard', {
                                state: {
                                    week: location?.state?.week,
                                }
                            })
                        }} />
                        <div className='bg-white p-5  border-b border-[#F0F0F0] pb-4 mb-6'>
                            <div className='border-b border-[#F0F0F0] pb-4 mb-6'>
                                <div className='w-full text-[#434343]'>
                                    {calenderData && calenderData?.singlejapaneseday?.split('年')[1].split('月')[0]}月{calenderData && calenderData?.singlejapaneseday?.split('年')[1].split('月')[1].split('日')[0]}日
                                    {calenderData && ' ' + calenderData?.japaneseday + ' '}
                                    の予約状況
                                </div>
                                {!(calenderData?.date < moment().format('YYYY-MM-DD')) && <div className='mt-6'>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            value=""
                                            className="sr-only peer"
                                            checked={calenderData?.isOpen && !(calenderData?.date < moment().format('YYYY-MM-DD'))}
                                            onChange={() => {
                                                handleToggle();
                                            }}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-700">{calenderData?.isOpen ? '定休日にする' : '定休日にする'}</span>
                                    </label>
                                </div>}
                            </div>
                            <div className='mt-5'>
                                <div className='text-[#434343] mb-2'>
                                    {(calenderData?.date < moment().format('YYYY-MM-DD')) ? '過去の会員の予約を確認できます。' : 'レッスンを受けられる会員数と会員の予約を変更できます。'}
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => {
                            }}
                            className={`flex items-center 
                    ${calenderData?.isOpen && !(calenderData?.date < moment().format('YYYY-MM-DD')) ? 'text-[#2F54EB]' : 'text-[#8C8C8C]'}
                    gap-2 flex-row mb-6 relative`}
                        >

                            <EditCalendarDropdown previousDate={checkIfPreviousDate(calenderData?.date)} dayFormat={calenderData?.dateFormat} setdropdown={setdropdown} dropdown={dropdown} dropdownValue={dropdownValue} setdropdownValue={setdropdownValue} openCalendar={openCalendar} toggleCalendar={toggleCalendar} day={calenderData} setOpenCalendar={setOpenCalendar} />
                        </button>
                        <div className='grid grid-cols-1 gap-3 sm:grid-cols-4 w-full mt-7 mb-4'>
                            {calenderData && calenderData?.timeSlots?.map((item, index) => {
                                return (
                                    !item?.isDefaultClose && (
                                        <CalendarEdit
                                            openToggle={calenderData?.isOpen}
                                            data={item}
                                            key={index}
                                            date={calenderData?.date}
                                            totalBookings={item?.members.length}
                                            users={users}
                                            calenderData={calenderData}
                                            setcalenderData={setcalenderData}
                                            addBooking={addBooking}
                                            removeBooking={removeBooking}
                                            canceledSlots={canceledSlots}
                                            setcanceledSlots={setCanceledSlots}
                                            setUsersToBeAdded={setUsersToBeAdded}
                                            usersToBeAdded={usersToBeAdded}
                                            setUsersToBeRemoved={setUsersToBeRemoved}
                                            usersToBeRemoved={usersToBeRemoved}
                                            removeAllState={removeAllState}
                                            toastError={toastError}
                                        />
                                    )
                                )
                            })}
                        </div>
                        {!checkIfPreviousDate(calenderData?.date) && (
                            <div className='flex justify-start items-center gap-8 mb-10'>
                                <button
                                    onClick={() => {
                                        if (usersToBeRemoved.length > 0) {
                                            setUpdateModal(true)
                                        }
                                        else if (canceledSlots.length > 0) {
                                            setCencelSlotModal(true)
                                        }
                                        else if (!isClose) {
                                            setOpenSlotModal(true)
                                        } else {
                                            setOrdinaryModal(true)
                                        }
                                    }}
                                    disabled={(calenderData?.date < moment().format('YYYY-MM-DD'))}
                                    className={`flex items-center 
                            ${!(calenderData?.date < moment().format('YYYY-MM-DD')) ? 'text-[#fff]' : 'text-[#8C8C8C]'} 
                                gap-2 flex-row-reverse 
                            ${!(calenderData?.date < moment().format('YYYY-MM-DD')) ? 'bg-[#2F54EB]' : 'bg-[#D9D9D9]'}
                            ${!(calenderData?.date < moment().format('YYYY-MM-DD')) ? 'cursor-pointer' : 'cursor-not-allowed'}
                                px-10 py-2`}
                                >
                                    変更を保存
                                </button>
                                <button
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                    className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse'
                                >
                                    キャンセル
                                </button>
                            </div>
                        )}
                    </div>
                    {updateModal && (
                        usersToBeRemoved.length > 0 && (
                            <Modal
                                showModal={updateModal}
                                setShowModal={setUpdateModal}
                                action={updateCalendar}
                                data={usersToBeRemoved}
                                body={`会員の予約をキャンセルされますが、変更を保存してもよろしいですか？`}
                                title='予約キャンセルの確認'
                                confirmButton={'いいえ'}
                                cancelButton={'変更を保存'}
                            />
                        )
                    )}
                    {cencelSlotModal && (
                        (canceledSlots.length > 0 && (
                            <DeleteModal
                                showModal={cencelSlotModal}
                                setShowModal={setCencelSlotModal}
                                action={updateCalendar}
                                data={canceledSlots}
                                body={`のコマをクローズしてもよろしいですか？`}
                                title='クローズの確認'
                                confirmButton={'いいえ'}
                                cancelButton={'クローズ'}
                                extraData={'すでに予約されたレッスンを全てキャンセルされます。'}
                                cancelAction={fixAllTimeSlots}
                            />
                        ))
                    )}
                    {openSlotModal && (
                        (!isClose && (
                            <DeleteModal
                                showModal={openSlotModal}
                                setShowModal={setOpenSlotModal}
                                action={updateCalendar}
                                data={calenderData}
                                body={`を定休日にしてもよろしいですか？`}
                                title='クローズの確認'
                                confirmButton={'いいえ'}
                                cancelButton={'クローズ'}
                                extraData={'すでに予約されたレッスンを全てキャンセルされます。'}
                                cancelAction={handleToggle}
                            />
                        ))
                    )}
                    {ordinaryModal && (
                        <Modal
                            showModal={ordinaryModal}
                            setShowModal={setOrdinaryModal}
                            action={updateCalendar}
                            data={''}
                            body={`スケジュールを保存してもよろしいですか？`}
                            title='変更の保存'
                            confirmButton={'キャンセル'}
                            cancelButton={'保存'}
                        />
                    )}
                    {updateTimeSlotsModal && (
                        <Modal
                            showModal={updateTimeSlotsModal}
                            setShowModal={setUpdateTimeSlotsModal}
                            action={updateTimeSlot}
                            data={''}
                            body={`${calenderData?.date} を${dropdownValue?.text}にしてもよろしいですか？`}
                            title={'時間割変更の確認'}
                            confirmButton={'いいえ'}
                            cancelButton={'変更'}
                            extraData={'すでに予約されたレッスンを全てキャンセルされます。'}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default CalenderEdit;
