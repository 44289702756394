import { useState, useContext, useRef, useCallback } from 'react'
import Header from '../user-components/Header'
import { UserContext } from '../context/userContext'
import moment from 'moment'
import Toast from '../utils/Toast'
import axios from 'axios'
import { handleEmailValidation } from '../utils/handleValidation'
import { useNavigate } from 'react-router-dom'

const AccountManagment = () => {
  const auth = useContext(UserContext)
  const navigate = useNavigate()
  const [tab, settab] = useState(1)
  const [showModal, setshowModal] = useState(false)
  const [email, setemail] = useState('')
  const [currentPassword, setcurrentPassword] = useState('')
  const [password, setpassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const flashActive = useRef(false)
  const flashMessage = useRef('')
  const flashType = useRef('')
  const [showToast, setShowToast] = useState(false);
  const [passwordChange, setpasswordChange] = useState(false)
  const [passwordVisible, setpasswordVisible] = useState(false)
  const [passwordVisible2, setpasswordVisible2] = useState(false)
  const [passwordVisible3, setpasswordVisible3] = useState(false)
  const [timer] = useState(3000);
  const { user, setUser } = auth

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!user) {
  //       window.location.href = '/'
  //     }
  //   }, 10000)
  // }, [user])

  const hideToast = useCallback(() => {
    flashActive.current = false;
    setShowToast(false);
  }, []);

  const handleShowToast = useCallback(() => {
    hideToast();
    if (flashMessage.current) {
      flashActive.current = true;
      setShowToast(true);
      window.setTimeout(hideToast, timer);
    }
  }, [flashMessage, hideToast, timer]);


  const changeEmailId = async () => {
    try {
      if (user?.email === email) {
        flashMessage.current = '現在のメールアドレスと同じです'
        flashType.current = 'error'
        handleShowToast()
        return
      }
      if (email === '') {
        flashMessage.current = 'メールアドレスを入力してください'
        flashType.current = 'error'
        handleShowToast()
        return
      } else {
        const emailValidation = handleEmailValidation(email)
        if (emailValidation === false) {
          flashMessage.current = 'メールアドレスの形式が正しくありません'
          flashType.current = 'error'
          handleShowToast()
          return
        }
        const res = await axios.post('/user/change-email', {
          email,
        })
        flashMessage.current = 'メールアドレスを変更しました'
        flashType.current = 'success'
        handleShowToast()
        setemail('')
        setUser(res.data.user)
        navigate('/user/email-change', { replace: true })
      }
    }
    catch (error) {
      flashMessage.current = error.response.data.error
      flashType.current = 'error'
      handleShowToast()
    }
  }

  const changePassword = async () => {
    try {
      if (password === '') {
        flashMessage.current = 'パスワードを入力してください'
        flashType.current = 'error'
        handleShowToast()
        return
      } else if (confirmPassword === '') {
        flashMessage.current = '確認用パスワードを入力してください'
        flashType.current = 'error'
        handleShowToast()
        return
      } else if (password !== confirmPassword) {
        flashMessage.current = 'パスワードが一致しません'
        flashType.current = 'error'
        handleShowToast()
        return
      } else {
        await axios.post('/user/change-password', {
          oldPassword: currentPassword,
          newPassword: password,
        })
        setcurrentPassword('')
        setpassword('')
        setconfirmPassword('')
        setpasswordChange(true)
      }
    } catch (error) {
      flashMessage.current = error.response.data.error
      flashType.current = 'error'
      handleShowToast()
    }
  }

  const showPassword = (text) => {
    if (text === 'current') {
      setpasswordVisible(!passwordVisible)
    } else if (text === 'new') {
      setpasswordVisible2(!passwordVisible2)
    } else {
      setpasswordVisible3(!passwordVisible3)
    }
  };

  return (
    !passwordChange ? (
      <div className='min-h-[calc(100vh-200px)] overflow-auto mb-10'>
        <Header type='account-managment' />
        {showToast && (
          <Toast
            type={flashType.current}
            message={flashMessage.current}
            duration={10000}
            active={flashActive.current}
            setActive={flashActive.current}
            position={'tcenter'}
          />
        )}
        <>
          <div className='bg-white p-5 pb-0'>
            <div className='text-[30px] font-bold'>アカウント管理</div>
            <div className='flex gap-7 mt-6 whitespace-nowrap  overflow-x-auto scrollbar-hide md:scrollbar-default'>
              <button
                onClick={() => {
                  settab(1)
                }}
                className={`pb-2 ${tab === 1
                  ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                  : 'text-[#434343]'
                  }`}
              >
                ご契約内容
              </button>
              <button
                onClick={() => {
                  settab(2)
                }}
                className={`pb-2 ${tab === 2
                  ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                  : 'text-[#434343]'
                  }`}
              >
                メールアドレスの変更
              </button>
              <button
                onClick={() => {
                  settab(3)
                }}
                className={`pb-2 ${tab === 3
                  ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                  : 'text-[#434343]'
                  }`}
              >
                パスワードの変更
              </button>
            </div>
          </div>
          <div className='p-5'>
            <div className='bg-white p-5'>
              {tab === 1 ? (
                <>
                  <div className='w-full border-b border-[#F0F0F0] pb-4 mb-4'>
                    <div className='text-[#434343] text-[20px] mb-2.5'>
                      {user?.kanjiName}さん　のご契約内容
                    </div>
                    <div className='text-[#8C8C8C]'>
                      更新日: {moment(user?.updatedAt).format('YYYY/MM/DD')}
                    </div>
                  </div>
                  {(user?.unApprovedMemberShipType !== '' || user?.unApprovedTicketCounts > 0) && (
                    <div className='flex gap-12 mb-8 text-red-500'>
                      現在ご契約内容の変更を申請しています。
                    </div>
                  )}
                  <div className='text-[#8C8C8C] mb-1'>会員タイプ</div>
                  <div className='text-[#141414] text-[22px] font-bold'>
                    {user?.memberShipType}
                  </div>

                  {user?.memberShipType === '回数券会員' && user?.ticketCount > 0 && (
                    <div className='w-full text-[#F5222D] text-[16px] mt-2'>
                      回数券がまだ残っているため、会員タイプを変更できません。
                    </div>
                  )}

                  <div className='flex gap-12 mt-4 mb-7'>
                    {user?.memberShipType !== '回数券会員' && (
                      <div>
                        <div className='text-[#8C8C8C] mb-1'>
                          今月の残りの予約可能回数
                        </div>
                        <div className='text-[#141414] text-[22px] font-bold'>
                          {user?.totalBookings ? user?.totalBookings : 0}
                          回
                        </div>
                      </div>
                    )}

                    <div>
                      <div className='text-[#8C8C8C] mb-1'>回数券</div>
                      <div className='text-[#141414] text-[22px] font-bold'>
                        {user?.ticketCount} 回
                      </div>
                    </div>
                  </div>
                </>
              ) : tab === 2 ? (
                <>
                  <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                    <div className='text-[#434343] text-[20px] mb-2.5'>
                      メールアドレスの変更
                    </div>
                  </div>

                  <div className='text-[#434343] text-[16px] mb-2.5'>
                    新メールアドレスに確認用のメールを送信いたします。
                  </div>

                  <div className='mt-10'>
                    <div className='text-[#434343] mb-2'>
                      現在のメールアドレス
                    </div>
                    <div className='px-2 py-2 bg-[#D9D9D9] text-[#8C8C8C] max-w-[300px]'>
                      {user?.email}
                    </div>
                  </div>

                  <div className='mt-6'>
                    <div className='text-[#434343] mb-2'>新メールアドレス</div>
                    <input
                      type='text'
                      onChange={(e) => {
                        setemail(e.target.value)
                      }}
                      placeholder='新メールアドレス'
                      className='max-w-[300px] w-full border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none'
                    />
                  </div>

                  <div className='flex items-start 3sm:items-center flex-col 3sm:flex-row gap-6 mt-12'>
                    <button
                      onClick={() => {
                        changeEmailId()
                      }}
                      disabled={email === '' || email === user?.email ? true : false}
                      className={`
                      ${email === '' || email === user?.email ? 'bg-[#D9D9D9]' : 'bg-[#2F54EB]'}
                      ${email === '' || email === user?.email ? 'text-[#8C8C8C]' : 'text-[#FFF]'}
                      px-6 py-2 rounded-[4px]`}>
                      変更を保存
                    </button>
                  </div>
                </>
              ) : tab === 3 ? (
                <>
                  <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                    <div className='text-[#434343] text-[20px] mb-2.5'>
                      パスワードの変更
                    </div>
                  </div>

                  <div className='mt-6'>
                    <div className='text-[#434343] mb-2'>現在のパスワード</div>
                    <div
                      className={'max-w-[300px] w-full text-[12px] relative'}
                    >
                      <input
                        onChange={(e) => {
                          setcurrentPassword(e.target.value)
                        }}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder='現在のパスワード'
                        className='max-w-[300px] w-full border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none'
                      />
                      <button
                        onClick={() => showPassword('current')}
                        className='absolute right-0 top-0 mt-2 mr-2 z-10'
                      >
                        {passwordVisible ? (
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.6822 5.5404C13.9894 1.97433 11.4305 0.179688 8.0001 0.179688C4.56796 0.179688 2.01082 1.97433 0.31796 5.54219C0.250059 5.68597 0.214844 5.843 0.214844 6.00201C0.214844 6.16102 0.250059 6.31805 0.31796 6.46183C2.01082 10.0279 4.56975 11.8225 8.0001 11.8225C11.4322 11.8225 13.9894 10.0279 15.6822 6.46005C15.8197 6.17076 15.8197 5.83505 15.6822 5.5404ZM8.0001 10.5368C5.11975 10.5368 3.01082 9.07612 1.52332 6.00112C3.01082 2.92612 5.11975 1.4654 8.0001 1.4654C10.8805 1.4654 12.9894 2.92612 14.4769 6.00112C12.9912 9.07612 10.8822 10.5368 8.0001 10.5368ZM7.92867 2.85826C6.19296 2.85826 4.78582 4.2654 4.78582 6.00112C4.78582 7.73683 6.19296 9.14397 7.92867 9.14397C9.66439 9.14397 11.0715 7.73683 11.0715 6.00112C11.0715 4.2654 9.66439 2.85826 7.92867 2.85826ZM7.92867 8.00112C6.82332 8.00112 5.92867 7.10647 5.92867 6.00112C5.92867 4.89576 6.82332 4.00112 7.92867 4.00112C9.03403 4.00112 9.92868 4.89576 9.92868 6.00112C9.92868 7.10647 9.03403 8.00112 7.92867 8.00112Z'
                              fill='#2F54EB'
                            />
                          </svg>
                        ) : (
                          // eyes off svg
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" fill='#2F54EB' />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" fill='#2F54EB' />
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" fill='#2F54EB' />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className='mt-6'>
                    <div className='text-[#434343] mb-2'>新パスワード</div>
                    <div
                      className={'max-w-[300px] w-full text-[12px] relative'}
                    >
                      <input
                        onChange={(e) => {
                          setpassword(e.target.value)
                        }}
                        type={passwordVisible2 ? 'text' : 'password'}
                        placeholder='新パスワード'
                        className='max-w-[300px] w-full border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none'
                      />
                      <button
                        onClick={() => showPassword('new')}
                        className='absolute right-0 top-0 mt-2 mr-2 z-10'
                      >
                        {passwordVisible2 ? (
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.6822 5.5404C13.9894 1.97433 11.4305 0.179688 8.0001 0.179688C4.56796 0.179688 2.01082 1.97433 0.31796 5.54219C0.250059 5.68597 0.214844 5.843 0.214844 6.00201C0.214844 6.16102 0.250059 6.31805 0.31796 6.46183C2.01082 10.0279 4.56975 11.8225 8.0001 11.8225C11.4322 11.8225 13.9894 10.0279 15.6822 6.46005C15.8197 6.17076 15.8197 5.83505 15.6822 5.5404ZM8.0001 10.5368C5.11975 10.5368 3.01082 9.07612 1.52332 6.00112C3.01082 2.92612 5.11975 1.4654 8.0001 1.4654C10.8805 1.4654 12.9894 2.92612 14.4769 6.00112C12.9912 9.07612 10.8822 10.5368 8.0001 10.5368ZM7.92867 2.85826C6.19296 2.85826 4.78582 4.2654 4.78582 6.00112C4.78582 7.73683 6.19296 9.14397 7.92867 9.14397C9.66439 9.14397 11.0715 7.73683 11.0715 6.00112C11.0715 4.2654 9.66439 2.85826 7.92867 2.85826ZM7.92867 8.00112C6.82332 8.00112 5.92867 7.10647 5.92867 6.00112C5.92867 4.89576 6.82332 4.00112 7.92867 4.00112C9.03403 4.00112 9.92868 4.89576 9.92868 6.00112C9.92868 7.10647 9.03403 8.00112 7.92867 8.00112Z'
                              fill='#2F54EB'
                            />
                          </svg>
                        ) : (
                          // eyes off svg
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" fill='#2F54EB' />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" fill='#2F54EB' />
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" fill='#2F54EB' />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className='mt-6'>
                    <div className='text-[#434343] mb-2'>新パスワード</div>
                    <div
                      className={'max-w-[300px] w-full text-[12px] relative'}
                    >
                      <input
                        onChange={(e) => {
                          setconfirmPassword(e.target.value)
                        }}
                        type={passwordVisible3 ? 'text' : 'password'}
                        placeholder='新パスワード'
                        className='max-w-[300px] w-full border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none'
                      />
                      <button
                        onClick={() => showPassword('confirm')}
                        className='absolute right-0 top-0 mt-2 mr-2 z-10'
                      >
                        {passwordVisible3 ? (
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.6822 5.5404C13.9894 1.97433 11.4305 0.179688 8.0001 0.179688C4.56796 0.179688 2.01082 1.97433 0.31796 5.54219C0.250059 5.68597 0.214844 5.843 0.214844 6.00201C0.214844 6.16102 0.250059 6.31805 0.31796 6.46183C2.01082 10.0279 4.56975 11.8225 8.0001 11.8225C11.4322 11.8225 13.9894 10.0279 15.6822 6.46005C15.8197 6.17076 15.8197 5.83505 15.6822 5.5404ZM8.0001 10.5368C5.11975 10.5368 3.01082 9.07612 1.52332 6.00112C3.01082 2.92612 5.11975 1.4654 8.0001 1.4654C10.8805 1.4654 12.9894 2.92612 14.4769 6.00112C12.9912 9.07612 10.8822 10.5368 8.0001 10.5368ZM7.92867 2.85826C6.19296 2.85826 4.78582 4.2654 4.78582 6.00112C4.78582 7.73683 6.19296 9.14397 7.92867 9.14397C9.66439 9.14397 11.0715 7.73683 11.0715 6.00112C11.0715 4.2654 9.66439 2.85826 7.92867 2.85826ZM7.92867 8.00112C6.82332 8.00112 5.92867 7.10647 5.92867 6.00112C5.92867 4.89576 6.82332 4.00112 7.92867 4.00112C9.03403 4.00112 9.92868 4.89576 9.92868 6.00112C9.92868 7.10647 9.03403 8.00112 7.92867 8.00112Z'
                              fill='#2F54EB'
                            />
                          </svg>
                        ) : (
                          // eyes off svg
                          <svg
                            width='18'
                            height='14'
                            viewBox='0 0 16 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" fill='#2F54EB' />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" fill='#2F54EB' />
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" fill='#2F54EB' />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>

                  <div className='flex items-start 3sm:items-center flex-col 3sm:flex-row gap-6 mt-12'>
                    <button
                      onClick={() => {
                        changePassword()
                      }}
                      disabled={password === '' || confirmPassword === '' || currentPassword === ''}
                      className={`
                      ${password === '' || confirmPassword === '' || currentPassword === '' ? 'bg-[#D9D9D9]' : 'bg-[#2F54EB]'}
                      ${password === '' || confirmPassword === '' || currentPassword === '' ? 'text-[#8C8C8C]' : 'text-[#FFF]'}
                      px-6 py-2 rounded-[4px]`}>
                      変更を保存
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
        <>
          <div
            onClick={() => {
              setshowModal(false)
            }}
            className={`fixed top-0 left-0 w-full min-h-screen max-h-screen overflow-y-auto flex items-center justify-center p-3 bg-black bg-opacity-50 z-50 transition ${showModal === true ? 'visible opacity-100' : 'invisible opacity-0'
              }`}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-5 max-w-[400px] w-[calc(100%-40px)] rounded-[4px] z-[100] transition ${showModal === true ? 'visible opacity-100' : 'invisible opacity-0'
              }`}
          >
            <div>
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_170_12581)'>
                  <path
                    d='M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z'
                    fill='#FAAD14'
                  />
                  <path
                    d='M9.82104 15.3214C9.82104 15.634 9.94522 15.9338 10.1662 16.1548C10.3873 16.3758 10.687 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.687 14.1429 10.3873 14.267 10.1662 14.4881C9.94522 14.7091 9.82104 15.0089 9.82104 15.3214ZM10.4103 12.5714H11.5889C11.6969 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.6969 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z'
                    fill='#FAAD14'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_170_12581'>
                    <rect width='22' height='22' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='text-[#434343] font-[500] mb-2.5 mt-4'>
              内容に変更があります
            </div>
            <div className='text-[#141414]'>
              契約情報を変更しましたが、申請せずに前の画面へ戻りますか？
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 items-center gap-3 mt-6 max-w-[300px] mx-auto'>
              <button
                onClick={() => {
                  setshowModal(false)
                }}
                className='text-[#2F54EB] border-2 border-[#2F54EB] px-5 py-2 rounded-[4px]'
              >
                編集を続ける
              </button>
              <button
                onClick={() => {
                  setshowModal(false)
                }}
                className='text-[white] bg-[#2F54EB] border-2 border-[#2F54EB] px-5 py-2 rounded-[4px]'
              >
                前へ戻る
              </button>
            </div>
          </div>
        </>
      </div>
    ) : (
      <div className='min-h-[calc(100vh-150px)] w-full text-center flex items-center justify-center flex-col mx-auto mb-24'>
        <svg
          width='70'
          height='70'
          viewBox='0 0 70 70'
          fill='none'
          className='mx-auto mb-5'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.131 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4688 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1562 50.1172 23.5703Z'
            fill='#52C41A'
          />
        </svg>
        <div>
          <div className='text-[#141414] mt-2'>パスワードを変更しました
          </div>
        </div>
        <button
          onClick={() => {
            setpasswordChange(false)
          }}
          className='w-full max-w-[150px] flex items-center justify-center mx-auto bg-white py-2 mt-16 border border-[#2F54EB] text-[#2F54EB] rounded-[2px]'
        >
          戻る
        </button>
      </div>
    )
  )
}

export default AccountManagment
