import React from 'react';
import moment from 'moment';

const TicketView = ({ user }) => {
    const ticketExpiration = moment(user?.ticketExpiration).format('YYYY/MM/DD');
    return (
        <div className='mt-4'>
            <div className='w-full text-[#434343]'>
                回数券
            </div>
            <div className='w-full text-[#434343] font-bold text-2xl mt-1'>
                {user?.ticketCount > 0 ? user?.ticketCount : 0 + ' '}
                回
                {user?.ticketCount > 0 && (
                    <span className='text-[#434343] font-bold text-2xl'>
                        {' '}
                        ({ticketExpiration} まで)
                    </span>
                )}
            </div>
        </div>
    );
}

export default TicketView;
