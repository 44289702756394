import React from 'react'

const CalendarDisable = ({ start_time, userBooking, userTickets, day, totalPeople }) => {
    const checkIfUserBooked = () => {
        if (userBooking) {
            const userBooked = userBooking?.length > 0 && userBooking?.filter(
                (booking) => booking.time === start_time && booking.date === day.singlejapaneseday
            )
            if (userBooked.length > 0) {
                return true
            }
            const userTicketsBooked = userTickets?.length > 0 && userTickets?.filter(
                (ticket) => ticket.time === start_time && ticket.date === day.singlejapaneseday
            )
            if (userTicketsBooked?.length > 0) {
                return true
            }
        }
        return false
    }
    return (
        <>
            <div className='flex flex-col justify-between items-center  min-h-[143px] text-center p-3 border border-[#ADC6FF]'>
                <div className='text-[#141414]'>{start_time}</div>
                <div className='flex items-center justify-center gap-2'>
                    <div className='font-bold text-[20px] text-[#141414] mt-1'>◯ 空きあり</div>
                </div>
                {checkIfUserBooked() ? (
                    <div className='w-full py-0 text-[#F5222D] mx-auto mt-6'>
                        予約済み
                    </div>
                ) : (
                    <button
                    disabled
                    className='w-full bg-[#D9D9D9] py-0 border-2 border-[#D9D9D9] text-[#8C8C8C] rounded-[4px] mx-auto mt-6'
                >
                    予約
                </button>
                )}
            </div>
        </>
    )
}

export default CalendarDisable
