import { useState, useCallback, useEffect, useContext, useRef } from 'react'
import Header from '../user-components/Header'
import CalendarWithButton from '../user-components/Calendar-With-Button'
import CalendarFull from '../user-components/Calendar-Full'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { UserContext } from '../context/userContext'
import Toast from '../utils/Toast'
import useWindowDimensions from '../utils/screenDimensions'
import CalendarBlankComp from '../user-components/CalendarBlankComponent'
import CalendarDisable from '../user-components/CalendarDisable'

const Calendar = () => {
  const auth = useContext(UserContext)
  const [confirmation, setconfirmation] = useState(false)
  const { width } = useWindowDimensions()
  const [week, setweek] = useState(0)
  const [finish, setfinish] = useState(false)
  const [date, setdate] = useState()
  const [time, settime] = useState()
  const [day, setday] = useState()
  const { user, setUser, calendar, getCalendar } = auth
  const flashActive = useRef(false)
  const flashMessage = useRef('')
  const flashType = useRef('')
  const [showToast, setShowToast] = useState(false);
  const [timer] = useState(1000);

  const hideToast = () => {
    flashActive.current = false;
    setShowToast(false);
  };

  const handleShowToast = (e) => {
    hideToast();
    if (flashMessage) {
      flashActive.current = true;
      setShowToast(true);
      window.setTimeout(hideToast, timer);
    }
  };

  const getCalendarData = useCallback(async () => {
    try {
      getCalendar(week)
    } catch (error) {
      window.location.href = '/'
      console.warn(error)
    }
  }, [getCalendar, week])

  useEffect(() => {
    getCalendarData()
  }, [getCalendarData])

  const changeWeek = (day) => {
    if (day === 'next') {
      setweek(week + 1)
    } else if (day === 'previous') {
      setweek(week - 1)
    }
  }

  const setConfirmationDate = (newday, date, newTime) => {
    setday(newday)
    setdate(date)
    settime(newTime)
    setconfirmation(true)
  }

  const handleBooking = async () => {
    try {
      const { data } = await axios.post('/user/bookings', {
        date: date,
        time: time,
      })
      setUser(data.user)
      setfinish(true)
    } catch (error) {
      if (error.response.data.error) {
        flashMessage.current = 'すでに予約済みです'
        flashType.current = 'error'
        handleShowToast()
      }
    }
  }

  const checUserBooking = () => {
    if (user?.memberShipType === '月12回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 3) {
        return false
      }
    } else if (user?.memberShipType === '月8回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
        return false
      }
    } else if (user?.memberShipType === '月4回会員') {
      if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
        return false
      }
    } else if (user?.memberShipType === '回数券会員') {
      if (user?.ticketDetails?.length === 1) {
        return false
      }
    }
    return true
  }

  const checkIfUserBookedThatDay = (day) => {
    if (user?.bookings?.some((booking) => booking?.date === day)) {
      return true
    } else if (user?.ticketDetails?.some((ticket) => ticket?.date === day)) {
      return true
    }
    return false
  }

  const checkIfUserIsInTheClass = (day, time) => {
    if (user?.bookings?.some((booking) => booking?.date === day && booking?.time === time)) {
      return true
    } else if (user?.ticketDetails?.some((ticket) => ticket?.date === day && ticket?.time === time)) {
      return true
    }
    return false
  }

  function isDateInNextMonth(dateString) {
    const date = moment(dateString);
    const nextMonth = moment().add(1, 'month');

    if (date.year() === nextMonth.year() && date.month() === nextMonth.month()) {
      return true;
    } else {
      return false;
    }
  }

  const checkIfUserHasTotalBookingsOrTicketCountLeft = (dateString) => {
    const ifNextMonth = isDateInNextMonth(dateString?.date)
    if (user?.bookings || user?.ticketCount) {
      if ((parseInt(user?.totalBookings) + parseInt(user?.ticketCount) > 0)) {
        return true
      }
      if (parseInt(user?.ticketCount) > 0) {
        return true
      }
      if (parseInt(user?.totalBookings) > 0) {
        return true
      }
      if (ifNextMonth) {
        return true
      }
      return false
    }
  }
  return (
    <div className='min-h-[calc(100vh-200px)] overflow-auto mb-10'>
      {showToast && (
        <Toast
          type={flashType.current}
          message={flashMessage.current}
          duration={3000}
          active={flashActive.current}
          setActive={flashActive.current}
          position={'tright'}
          width={'small'}
        />
      )}
      {finish === false ? (
        <>
          <Header type='calendar' />
          <div className='p-5 w-full h-[calc(100vh-74px)] overflow-auto'>
            {confirmation === false ? (
              <div className='bg-white p-5'>
                <div className='flex items-center justify-between w-full mb-16 3sm:mb-6'>
                  <button
                    onClick={() => {
                      changeWeek('previous')
                    }}
                    className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse'
                  >
                    {width > 768 ? '先週' : '前日'}
                    <svg
                      width='10'
                      height='15'
                      viewBox='0 0 7 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                        fill='#2F54EB'
                      />
                    </svg>
                  </button>
                  <div className='4sm:text-[18px] text-[#434343] -mb-20 3sm:mb-0'>
                    {calendar?.length === 1 ?
                      width > 768 ? calendar && calendar[0]?.singlejapaneseday + calendar[0].japaneseday : calendar && calendar[0]?.singlejapaneseday + calendar[0].japaneseday
                      :
                      calendar && calendar[0]?.firstAndLastDayInJapanese
                    }
                  </div>
                  <button
                    onClick={() => {
                      changeWeek('next')
                    }}
                    className='flex items-center text-[#2F54EB] gap-2'
                  >
                    {width > 768 ? '来週' : '翌日'}
                    <svg
                      width='10'
                      height='15'
                      viewBox='0 0 9 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M8.45949 6.55692L0.535291 0.367666C0.514585 0.351365 0.489701 0.341234 0.463496 0.338438C0.437292 0.335641 0.41083 0.340292 0.38715 0.351857C0.36347 0.363422 0.343532 0.381431 0.329626 0.403816C0.31572 0.426202 0.308409 0.452056 0.308534 0.478409V1.8372C0.308534 1.92333 0.348964 2.00595 0.415761 2.05868L6.74387 6.99989L0.415761 11.9411C0.347206 11.9938 0.308534 12.0765 0.308534 12.1626V13.5214C0.308534 13.6392 0.443885 13.7042 0.535291 13.6321L8.45949 7.44286C8.52684 7.39033 8.58132 7.32312 8.6188 7.24636C8.65627 7.1696 8.67575 7.08531 8.67575 6.99989C8.67575 6.91448 8.65627 6.83018 8.6188 6.75342C8.58132 6.67666 8.52684 6.60946 8.45949 6.55692Z'
                        fill='#2F54EB'
                      />
                    </svg>
                  </button>
                </div>
                <div className='flex items-center justify-between w-full mb-6'>
                  <div className='flex items-center gap-2'>
                    {(user?.memberShipType === '月4回会員' || user?.memberShipType === '月8回会員' || user?.memberShipType === '回数券会員') && (user?.bookings?.length >= 1 || user?.ticketDetails?.length >= 1) && (
                      <div className='text-[#FF4D4F]'>レッスンをキャンセルまた完了しない時は、新しいレッスンを予約できません。</div>
                    )}
                    {(user?.memberShipType === '月12回会員') && (user?.bookings?.length >= 3) && (
                      <div className='text-[#FF4D4F]'>レッスンをキャンセルまた完了しない時は、新しいレッスンを予約できません。</div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-7 w-full mt-8 mb-10">
                  {calendar && calendar?.map((day, index) => (
                    <div key={index} className="text-[#434343]">
                      <div key={index} className={`
                    hidden sm:flex items-center justify-center text-[#434343] mb-2
                    ${day?.isToday ?
                          'border-t-2 border-[#2F54EB] -mt-0.5'
                          :
                          ''}
                    `}>
                        {day?.japanesedate}
                      </div>
                      {
                        day?.timeSlots?.map((time, index) => (
                          day?.isGreaterThanOrEqualToToday ?
                            (
                              !day?.isOpen ? (
                                <div
                                  key={index}
                                  className='flex items-center justify-center min-h-[143px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                  <div
                                    key={index}
                                    className='flex flex-col items-center justify-center gap-5'>
                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                  </div>
                                </div>
                              ) : (
                                (time?.isDefaultClose) ? (
                                  <CalendarBlankComp start_time={time?.start_time} day={day} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} />
                                ) : (time?.defaultSeats === 0) ? (
                                  <CalendarFull start_time={time?.start_time} day={day} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 ? user?.bookings : user?.ticketDetails} />
                                ) : (
                                  (time?.defaultSeats === time.members?.length) ? (
                                    <CalendarFull start_time={time?.start_time} day={day} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 ? user?.bookings : user?.ticketDetails} />
                                  ) : (
                                    day?.date === moment().format('YYYY-MM-DD') &&
                                      time.start_time < moment().format('HH:mm')
                                      ? (
                                        <CalendarFull start_time={time?.start_time} day={day} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 ? user?.bookings : user?.ticketDetails} />
                                      ) : !checUserBooking() || checkIfUserBookedThatDay(day?.singlejapaneseday) || checkIfUserIsInTheClass(day?.singlejapaneseday, time.start_time) || (!checkIfUserHasTotalBookingsOrTicketCountLeft(day)) ? (
                                        <CalendarDisable start_time={time.start_time} day={day} key={index} totalPeople={time.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings} userTickets={user?.ticketDetails?.length > 0 && user?.ticketDetails} />
                                      ) : (
                                        <CalendarWithButton start_time={time.start_time} day={day} key={index} totalPeople={time.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings?.length > 0 ? user?.bookings : user?.ticketDetails} />
                                      )
                                  )
                                )
                              )
                            ) : (
                              !day?.isOpen ? (
                                <div
                                  key={index}
                                  className='flex items-center justify-center min-h-[143px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                  <div
                                    key={index}
                                    className='flex flex-col items-center justify-center gap-5'>
                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                  </div>
                                </div>
                              ) : (
                                (time?.isDefaultClose) ? (
                                  <CalendarBlankComp start_time={time.start_time} day={day} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} />
                                ) : (
                                  <CalendarFull start_time={time.start_time} day={time} key={index} totalPeople={time?.members?.length} setconfirmation={setConfirmationDate} userBooking={user?.bookings.length > 0 ? user?.bookings : user?.ticketDetails} />
                                )
                              )
                            )
                        ))
                      }

                    </div>
                  ))}

                </div>
              </div>
            ) : (
              <>
                <button
                  onClick={() => {
                    setconfirmation(false)
                  }}
                  className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse mb-6'
                >
                  戻る
                  <svg
                    width='10'
                    height='15'
                    viewBox='0 0 7 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                      fill='#2F54EB'
                    />
                  </svg>
                </button>
                <div className='bg-white p-5'>
                  <div className='w-full text-[#434343] border-b border-[#F0F0F0] pb-4 mb-6'>
                    予約の確認
                  </div>
                  <div className='text-[#434343] mt-6'>
                    下記のご予約を確定してもよろしいですか
                  </div>

                  <div className='mt-10'>
                    <div className='text-[#8C8C8C] text-sm mb-1'>日時</div>
                    <div className='flex items-center gap-5 sm:gap-10'>
                      <div className='text-[#141414] font-bold text-[24px]'>
                        {date.split('年')[1]} {date.split('年')[2]}
                        {day}
                        {' ' + time} -
                        {moment(time, 'HH:mm').add(1.5, 'hours').format('HH:mm')}
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center flex-wrap gap-3 mt-6'>
                    <button
                      onClick={() => {
                        setconfirmation(false)
                      }}
                      className='text-[#2F54EB] px-5 py-1.5 border-2 border-[#2F54EB]'
                    >
                      いいえ
                    </button>
                    <button
                      onClick={() => {
                        handleBooking()
                      }}
                      className='text-white px-5 py-1.5 border-2 border-[#2F54EB] bg-[#2F54EB]'
                    >
                      予約する
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='min-h-[calc(100vh-150px)] max-w-[300px] text-center flex items-center justify-center flex-col mx-auto mb-24'>
            <div>
              <svg
                width='70'
                height='70'
                viewBox='0 0 70 70'
                fill='none'
                className='mx-auto mb-5'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.131 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4688 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1562 50.1172 23.5703Z'
                  fill='#52C41A'
                />
              </svg>
              <div className='text-[#141414]'>予約が完了いたしました</div>
            </div>

            <Link
              to='/user/dashboard'
              className='w-full max-w-[150px] flex items-center justify-center mx-auto bg-white py-2.5 mt-16 border border-[#2F54EB] text-[#2F54EB] rounded-[2px]'
            >
              マイページへ
            </Link>
          </div>

          <div className='absolute bottom-3 left-0 w-full flex items-center justify-center flex-col gap-2 px-5'>

            <div className='text-center text-[#434343]'>
              Copyright (C) DTS KICKBOXING GYM
              {' ' + new Date().getFullYear() + ' '}
              All Rights Reserved
            </div>
          </div>
        </>
      )
      }
    </div>
  )
}

export default Calendar
