import React from 'react'
import moment from 'moment'

const CalendarFull = ({ start_time, day }) => {

    const checIFFutureClass = () => {
        const currentDayAndTime = moment().format('YYYY-MM-DD HH:mm')
        const classTime = moment(`${day.singlejapaneseday} ${start_time}`, 'YYYY-MM-DD HH:mm')
        if (classTime.isAfter(currentDayAndTime)) {
            return true
        }
        return false
    }
    return (
        <>
            <div className={`flex flex-col 
            ${checIFFutureClass() ? 'justify-between' : 'justify-center'}
            items-center  min-h-[143px] text-center p-3 border border-[#ADC6FF]`}>
                <div className='text-[#141414]'>{start_time}</div>
                {checIFFutureClass() ? (
                    <div className='flex items-center justify-center gap-2'>
                        <div className='font-bold text-[20px] text-[#141414] mt-1'>満席</div>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default CalendarFull
