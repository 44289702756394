import { useState } from 'react'
import moment from 'moment'
import CalendarLock from '../admin-components/Calendar-Lock'
import CalendarSchedule from '../admin-components/Calendar-Schedule'
import useWindowDimensions from '../utils/screenDimensions'
import { Link } from 'react-router-dom'
import RightArrow from '../assets/right-arrow.png'
import CalendarDropdown from '../admin-components/CalendarDropdown'
import CalendarModal from '../admin-components/CalendarTile'

const CalenderComponent = ({ week, isModify, calendar, setcalendar, setisModify, updateTimeSlots, todaysDate }) => {
    const { width } = useWindowDimensions()
    const [currentDate] = useState(moment().format('YYYY-MM-DD'))
    const [dropdown, setdropdown] = useState(false);
    const [dropdownValue, setdropdownValue] = useState([{ date: '', data: '' }, { date: '', data: '' }, { date: '', data: '' }, { date: '', data: '' }, { date: '', data: '' }, { date: '', data: '' }]);
    const [openCalendar, setOpenCalendar] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [currentUser, setCurrentUser] = useState('')
    const [totalUser, setTotalUser] = useState('')
    const [usersDetails, setUsersDetails] = useState([])

    const handleToggle = (day) => {
        if (day.date <= currentDate) {
            return
        }
        if (day.date === currentDate && day.timeSlots[0].start_time < moment().format('HH:mm')) {
            return
        }
        if (day.isOpen) {
            setcalendar(calendar.map((newDay) => {
                if (newDay.date === day.date) {
                    return {
                        ...day,
                        open: false,
                        isOpen: false
                    }
                }
                return newDay
            }))
        } else {
            setcalendar(calendar.map((newDay) => {
                if (newDay.date === day.date) {
                    return {
                        ...day,
                        open: true,
                        isOpen: true
                    }
                }
                return newDay
            }))
        }
    }

    const setIsPublishedHandler = () => {
        setcalendar(calendar.map((day) => {
            return {
                ...day,
                isPublished: true
            }
        }))
        setisModify(true)
    }

    const toggleCalendar = (date, text) => {
        if (dropdownValue.length === 1 && dropdownValue[0].date === '') {
            setdropdownValue([{ date: date, data: text }])
        } else {
            let newDate = true
            dropdownValue.forEach((value) => {
                if (value.date === date) {
                    newDate = false
                }
            })
            if (newDate) {
                setdropdownValue([...dropdownValue, { date: date, data: text }])
            }
        }
        updateTimeSlots(date, text)
    }

    const ifToday = (day) => {
        const todayDate = moment().format('YYYY-MM-DD')
        if (day.date === todayDate) {
            return true
        }
        return false
    }

    return (
        width > 768 ? (
            <div className="grid grid-cols-1 sm:grid-cols-7 w-full mt-7 relative p-5">
                {calendar?.map((day, index) => (
                    <div key={index} className="col-span-1 text-[#434343]">
                        <div key={index} className={`
                    hidden sm:flex items-center justify-center text-[#434343]
                    ${day.isToday ? 'border-t-2 border-[#2F54EB]' : ''}
                    `}>
                            {day?.japanesedate}
                        </div>
                        {isModify ? (
                            <>
                                <div
                                    className='flex mt-2 justify-center items-center mb-2' key={index}>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className={`sr-only peer`}
                                            onChange={e => { }}
                                            onClick={() => {
                                                handleToggle(day)
                                            }}
                                            checked={day.isOpen}
                                            key={index}
                                        />
                                        <div className={`w-11 h-6 bg-gray-200 rounded-full 
                                        peer 
                                        peer-focus:ring-4 
                                        peer-focus:ring-blue-300 
                                        dark:peer-focus:ring-blue-800 
                                        dark:bg-gray-700 
                                        peer-checked:after:translate-x-full
                                        peer-checked:after:border-white 
                                        after:content-[''] 
                                        after:absolute after:top-0.5 
                                        after:left-[2px] 
                                        after:bg-white 
                                        after:border-gray-300 
                                        after:border 
                                        after:rounded-full 
                                        after:h-5 
                                        after:w-5 
                                        after:transition-all 
                                        dark:border-gray-600 
                                        ${day.isGreaterThanOrEqualToToday && !ifToday(day) ? 'peer-checked:bg-blue-600' : 'peer-checked:bg-gray-300'}
                                    `
                                        }></div>
                                        <span className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-700">{day.isOpen ? 'クローズ' : 'オープン'}</span>
                                    </label>
                                </div>
                                <CalendarDropdown dayFormat={day?.dateFormat} setdropdown={setdropdown} dropdown={dropdown} dropdownValue={dropdownValue} setdropdownValue={setdropdownValue} openCalendar={openCalendar} toggleCalendar={toggleCalendar} day={day} setOpenCalendar={setOpenCalendar} />

                            </>
                        ) : (
                            !day?.isPublished ? (
                                <div
                                    className='mb-16'
                                />
                            ) : (
                                <Link
                                    to={`/admin/calendar-edit/${day?.date}`}
                                    state={{ week: week }}
                                    className={`
                    flex items-center justify-center py-2 mt-2 border border-[#2F54EB] text-[#2F54EB] ml-4 mr-4 mb-2 cursor-pointer
                    `}>
                                    {day.isGreaterThanOrEqualToToday ? '編集' : '詳細を見る'}
                                </Link>
                            )
                        )}
                    </div>
                ))
                }
                {
                    calendar?.map((day, dayIndex) => (
                        <div key={dayIndex} className="col-span-1 text-[#434343]">
                            {(day?.isPublished === true) ? (
                                day?.timeSlots.map((time, index) => (
                                    <div
                                        onClick={() => {
                                            if (!isModify && !time.isDefaultClose) {
                                                setSelectedDate(day)
                                                setStartTime(time?.start_time)
                                                setEndTime(time?.end_time)
                                                setCurrentUser(time?.members.length > 0 ? time?.members.length : 0)
                                                setTotalUser(time?.defaultSeats)
                                                setUsersDetails(time?.members)
                                                setShowModal(true)
                                            }
                                        }}
                                        key={index}
                                        className='cursor-pointer'
                                    >
                                        {day?.isGreaterThanOrEqualToToday ?
                                            (
                                                (isModify) ? (
                                                    !day?.isOpen
                                                        ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                                <div
                                                                    key={index}
                                                                    className='flex flex-col items-center justify-center gap-5'>
                                                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            (time.isDefaultClose) ? (
                                                                <div
                                                                    key={index}
                                                                    className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                                </div>
                                                            ) : (
                                                                <CalendarSchedule start_time={time?.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} isToggle={day.isOpen} todayDate={day.date} setcalendar={setcalendar} calendar={calendar} defaultSeats={time?.defaultSeats} isDefaultClose={time?.isDefaultClose} isModify={isModify} />
                                                            )
                                                        )
                                                ) : (
                                                    !day?.isOpen
                                                        ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                                <div
                                                                    key={index}
                                                                    className='flex flex-col items-center justify-center gap-5'>
                                                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                                </div>
                                                            </div>
                                                        ) : (time?.isDefaultClose) ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                            </div>
                                                        ) : (
                                                            time?.defaultSeats === 0 ? (
                                                                <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                            ) : (
                                                                <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                            )
                                                        )
                                                )
                                            )
                                            :
                                            (
                                                !day?.isOpen
                                                    ? (
                                                        <div
                                                            key={index}
                                                            className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                            <div
                                                                key={index}
                                                                className='flex flex-col items-center justify-center gap-5'>
                                                                {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                            </div>
                                                        </div>
                                                    ) : (time?.isDefaultClose) ? (
                                                        <div
                                                            key={index}
                                                            className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                        </div>
                                                    ) : (
                                                        time?.defaultSeats === 0 ? (
                                                            <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                        ) : (
                                                            <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                        )
                                                    )
                                            )}
                                    </div>
                                ))
                            ) : (
                                <div className=' flex-col absolute inset-0 flex justify-center items-center z-10 mt-40'>
                                    <div className='text-[#434343] font-medium'>
                                        {dayIndex === 0 && 'まだレッスンスケジュールを公開されていません'}
                                    </div>
                                    <div
                                        onClick={() => {
                                            setIsPublishedHandler()
                                        }}
                                        className='flex-row absolute inset-0 flex justify-center items-center z-10 mt-8 text-[#2F54EB] cursor-pointer'>
                                        {dayIndex === 0 && 'スケジュール作成へ'}
                                        {dayIndex === 0 && (
                                            <img
                                                src={RightArrow}
                                                alt='right-arrow'
                                                className='ml-2'
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                    ))
                }
                {showModal && (
                    <CalendarModal
                        week={week}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        date={selectedDate}
                        start_time={startTime}
                        end_time={endTime}
                        currentUser={currentUser}
                        totalUser={totalUser}
                        usersDetails={usersDetails}
                        confirmButton={'閉じる'}
                        cancelButton={'編集'}
                    />
                )}
            </div >
        ) : (
            <div className="grid grid-cols-1 sm:grid-cols-7 w-full mt-7">
                {calendar?.map((day, index) => (
                    (day?.date === todaysDate) && (
                        <div key={index} className="text-[#434343] mb-4">
                            <div key={index} className={`
                    hidden sm:flex items-center justify-center text-[#434343] mb-4
                    ${day.isToday ? 'border-t-2 border-[#2F54EB]' : ''}
                    `}>
                                {day?.japanesedate}
                            </div>
                            {isModify ? (
                                <>
                                    <div
                                    className='flex mt-2 justify-center items-center mb-2' key={index}>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className={`sr-only peer`}
                                            onChange={e => { }}
                                            onClick={() => {
                                                handleToggle(day)
                                            }}
                                            checked={day.isOpen}
                                            key={index}
                                        />
                                        <div className={`w-11 h-6 bg-gray-200 rounded-full 
                                            peer 
                                            peer-focus:ring-4 
                                            peer-focus:ring-blue-300 
                                            dark:peer-focus:ring-blue-800 
                                            dark:bg-gray-700 
                                            peer-checked:after:translate-x-full
                                            peer-checked:after:border-white 
                                            after:content-[''] 
                                            after:absolute after:top-0.5 
                                            after:left-[2px] 
                                            after:bg-white 
                                            after:border-gray-300 
                                            after:border 
                                            after:rounded-full 
                                            after:h-5 
                                            after:w-5 
                                            after:transition-all 
                                            dark:border-gray-600 
                                            ${day.isGreaterThanOrEqualToToday && !ifToday(day) ? 'peer-checked:bg-blue-600' : 'peer-checked:bg-gray-300'}
                                    `
                                        }></div>
                                        <span className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-700">{day.isOpen ? 'クローズ' : 'オープン'}</span>
                                    </label>
                                </div>
                                    <CalendarDropdown dayFormat={day?.dateFormat} setdropdown={setdropdown} dropdown={dropdown} dropdownValue={dropdownValue} setdropdownValue={setdropdownValue} openCalendar={openCalendar} toggleCalendar={toggleCalendar} day={day} setOpenCalendar={setOpenCalendar} />
                                </>
                            ) : (
                                <Link
                                    to={`/admin/calendar-edit/${day?.date}`}
                                    className={`
                    flex items-center justify-center py-2 mt-2 border border-[#2F54EB] text-[#2F54EB] ml-4 mr-4 mb-2
                    `}>
                                    {day?.isGreaterThanOrEqualToToday ? '編集' : '詳細を見る'}
                                </Link>
                            )}
                            {(day?.isPublished === true) ? (
                                day?.timeSlots.map((time, index) => (
                                    <div
                                        onClick={() => {
                                            if (!isModify && !time.isDefaultClose) {
                                                setSelectedDate(day)
                                                setStartTime(time?.start_time)
                                                setEndTime(time?.end_time)
                                                setCurrentUser(time?.members.length > 0 ? time?.members.length : 0)
                                                setTotalUser(time?.defaultSeats)
                                                setUsersDetails(time?.members)
                                                setShowModal(true)
                                            }
                                        }}
                                        key={index}
                                        className='cursor-pointer'
                                    >
                                        {day?.isGreaterThanOrEqualToToday ?
                                            (
                                                (isModify) ? (
                                                    !day?.isOpen
                                                        ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                                <div
                                                                    key={index}
                                                                    className='flex flex-col items-center justify-center gap-5'>
                                                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            (time.isDefaultClose) ? (
                                                                <div
                                                                    key={index}
                                                                    className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                                </div>
                                                            ) : (
                                                                <CalendarSchedule start_time={time?.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} isToggle={day.isOpen} todayDate={day.date} setcalendar={setcalendar} calendar={calendar} defaultSeats={time?.defaultSeats} isDefaultClose={time?.isDefaultClose} isModify={isModify} />
                                                            )
                                                        )
                                                ) : (
                                                    !day?.isOpen
                                                        ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                                <div
                                                                    key={index}
                                                                    className='flex flex-col items-center justify-center gap-5'>
                                                                    {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                                </div>
                                                            </div>
                                                        ) : (time?.isDefaultClose) ? (
                                                            <div
                                                                key={index}
                                                                className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                            </div>
                                                        ) : (
                                                            time?.defaultSeats === 0 ? (
                                                                <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                            ) : (
                                                                <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                            )
                                                        )
                                                )
                                            )
                                            :
                                            (
                                                !day?.isOpen
                                                    ? (
                                                        <div
                                                            key={index}
                                                            className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                                            <div
                                                                key={index}
                                                                className='flex flex-col items-center justify-center gap-5'>
                                                                {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                                            </div>
                                                        </div>
                                                    ) : (time?.isDefaultClose) ? (
                                                        <div
                                                            key={index}
                                                            className='flex items-center justify-center min-h-[200px] text-center p-3 bg-[#FFF]'>
                                                        </div>
                                                    ) : (
                                                        time?.defaultSeats === 0 ? (
                                                            <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                        ) : (
                                                            <CalendarLock start_time={time.start_time} people={time?.members} key={index} totalPeople={time.defaultSeats} />
                                                        )
                                                    )
                                            )}
                                    </div>
                                ))
                            ) : (
                                <div className=' flex-col absolute inset-0 flex justify-center items-center z-10 mt-80'>
                                    <div className='text-[#434343] font-medium'>
                                        まだレッスンスケジュールを公開されていません
                                    </div>
                                    <div
                                        onClick={() => {
                                            setIsPublishedHandler()
                                        }}
                                        className='flex-row absolute inset-0 flex justify-center items-center z-10 mt-12 text-[#2F54EB] cursor-pointer'>
                                        スケジュール作成へ
                                        <img
                                            src={RightArrow}
                                            alt='right-arrow'
                                            className='ml-2'
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )))
                }
                {showModal && (
                    <CalendarModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        date={selectedDate}
                        start_time={startTime}
                        end_time={endTime}
                        currentUser={currentUser}
                        totalUser={totalUser}
                        usersDetails={usersDetails}
                        confirmButton={'閉じる'}
                        cancelButton={'編集'}
                    />
                )}
            </div>
        )
    )
}

export default CalenderComponent