import React, { useContext } from 'react'
import { UserContext } from '../context/userContext'

function checkIfUserEligibleForBooking(booking, day, start_time, user) {
  const isUserBooking = booking?.length > 0 && booking?.find(
    (booking) => booking?.time === start_time && booking?.date === day?.singlejapaneseday
  )
  const isUserMemberShipThreeTimes = user?.membershipType === '月12回会員'
  const isUserMemberShipTwoTimes = user?.membershipType === '月8回会員'
  const isUserMemberShipOneTime = user?.membershipType === '月4回会員'
  const isUserHasTickets = user?.ticketCount > 0
  if (isUserBooking) {
    return true
  }
  if (isUserMemberShipThreeTimes && booking?.length >= 3) {
    return true
  }
  if (isUserMemberShipTwoTimes && booking?.length >= 2) {
    return true
  }
  if (isUserMemberShipOneTime && booking?.length >= 1) {
    return true
  }
  if (isUserHasTickets) {
    return false
  }
  return false
}

const CalendarWithButton = ({ day, start_time, totalPeople, setconfirmation, userBooking }) => {
  const auth = useContext(UserContext)
  const { user } = auth
  const isUserBooking = checkIfUserEligibleForBooking(
    userBooking,
    day,
    start_time,
    user
  )
  const isUserHasBookedToday = userBooking?.length > 0 && userBooking?.filter(
    (booking) => booking.date === day?.singlejapaneseday
  )
  return (
    <>
      <div className='flex flex-col justify-between items-center  min-h-[143px] text-center p-3 border border-[#ADC6FF]'>
        <div className='text-[#141414]'>{start_time}</div>
        <div className='flex items-center justify-center gap-2'>
          <div className='font-bold text-[20px] text-[#141414] mt-1'>
            <p className='text-[#000]'>◯ 空きあり</p>
          </div>
        </div>
        <button
            onClick={() => {
              setconfirmation(day?.japaneseday, day?.singlejapaneseday, start_time, null, 'booking')
            }}
            disabled={isUserBooking || isUserHasBookedToday?.length >= 1}
            className={`w-full 
          ${(isUserBooking || isUserHasBookedToday?.length >= 1) ? 'bg-[#D9D9D9] text-[#8C8C8C]' : 'bg-white'}
          py-0 border-2 
          ${(isUserBooking || isUserHasBookedToday?.length >= 1) ? 'border-[#D9D9D9]' : 'border-[#2F54EB]'} 
          text-[#2F54EB] 
          rounded-[4px] 
          mx-auto 
          mt-6`}
          >
            予約
          </button>
      </div>
    </>
  )
}

export default CalendarWithButton
