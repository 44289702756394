import { Table } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const UserTable = ({ users, callback, onDelete }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: 'お名前',
            dataIndex: 'kanjiName',
            key: 'name',
            sorter: (a, b) => a.hiraganaName.localeCompare(b.hiraganaName),
        },
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: '会員タイプ',
            key: 'memberShipType',
            dataIndex: 'memberShipType',
            sorter: (a, b) => a.memberShipType.localeCompare(b.memberShipType),
        },
        {
            title: '登録日',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => {
                return moment(text).format('YYYY/MM/DD');
            },
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        {
            title: 'アクション',
            dataIndex: 'action',
            key: 'x',
            render: (text, record) => {
                return (
                    <div className='flex flex-row gap-4' key={record._id}>
                        <div
                            onClick={() => onDelete(record.email)}
                            className='text-red-500 font-normal cursor-pointer'>
                            削除
                        </div>
                        <div className='border-r-2 border-gray-300 h-5'></div>
                        <Link
                            to='/admin/edit-user'
                            state={{ email: record?.email }}
                            className='text-blue-500 font-normal'>詳細を見る</Link>
                    </div>
                );
            }
        }
    ];
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        callback(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return <Table rowSelection={rowSelection} columns={columns} dataSource={users} key={'users'} />;
};
export default UserTable;