import React, { useState } from 'react';
import Header from '../admin-components/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useWindowDimensions from '../utils/screenDimensions';
import Dropdown from '../admin-components/Dropdown';
import EditDate from '../admin-components/Edit-Date';
import AddCoupan from '../admin-components/Add-Coupan';
import { useEffect } from 'react';

const InviteUserPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [dropdown, setdropdown] = React.useState(false);
    const [dropdownValue, setdropdownValue] = React.useState('月4回会員');
    const [currentDate, setCurrentDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 3)))
    const [addcoupon, setaddcoupon] = useState(true)
    const [ticketNumber, setTicketNumber] = useState(0)
    const { width } = useWindowDimensions();

    const inviteUser = async () => {
        try {
            await axios.post('/admin/invite-user', {
                email,
                memberShipType: dropdownValue,
                kanjiName: name,
                ticketNumber,
                ticketExpiration: endDate,
            });
            // navigate pass tab
            navigate('/admin/account-managment', { state: { tab: 3 } });
        } catch (error) {
            console.log(error);
        }
    };

    const handleTicketNumber = (e) => {
        setTicketNumber(e.target.value)
    }

    useEffect(() => { }, [currentDate, endDate])

    const changeDate = (e, type) => {
        if (dropdown !== '回数券会員') {
            if (type === 'currentDate') {
                setCurrentDate(e)
                setEndDate(new Date(e).setDate(new Date(e).getDate() + 10))
            } else {
                setCurrentDate(new Date(e).setDate(new Date(e).getDate() - 10))
                setEndDate(e)
            }
        } else {
            if (type === 'currentDate') {
                setCurrentDate(e)
                setEndDate(new Date(new Date(e).setMonth(new Date().getMonth() + 3)))
            } else {
                setEndDate(new Date(new Date(e).setMonth(new Date().getMonth() - 3)))
                setEndDate(e)
            }
        }
    }
    return (
        <div>
            <Header type='management' />
            <div className='w-full h-screen mb-10'>
                <div className='p-5'>
                    <button
                        onClick={() => {
                            navigate(-1)
                        }}
                        className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse mb-6'
                    >
                        戻る
                        <svg
                            width='10'
                            height='15'
                            viewBox='0 0 7 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                                fill='#2F54EB'
                            />
                        </svg>
                    </button>
                    <div className='bg-white p-5  border-b border-[#F0F0F0] pb-4 mb-6'>
                        <div className='border-b border-[#F0F0F0] pb-4 mb-6'>
                            <div className='w-full text-[#434343] font-medium'>
                                新規会員追加
                            </div>
                        </div>
                        {/* *必須項目 content in red color */}
                        <div className='flex items-center gap-2'>
                            <div className='content-[#ff4d4d] text-[#f24c4c]'>*</div>
                            <div className='text-[#f24c4c]'>必須項目</div>
                        </div>


                        <div className='mt-6 mb-6'>
                            <div className='flex items-center gap-2 mb-2'>
                                <div className='text-[#434343]'>メールアドレス</div>
                                <div className='content-[#ff4d4d] text-[#f24c4c]'>*</div>
                            </div>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                type="text" placeholder='メールアドレス' className={`
                                ${width < 768 ? 'w-full' : 'w-1/5'}
                                border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none`} />
                        </div>
                        <Dropdown dropdownValue={dropdownValue} setdropdownValue={setdropdownValue} dropdown={dropdown} setdropdown={setdropdown} width={width} />

                        {dropdownValue === '回数券会員' && (
                            <AddCoupan addcoupon={addcoupon} setaddcoupon={setaddcoupon} />
                        )}

                        {dropdownValue === '回数券会員' && addcoupon ? (
                            <EditDate endDate={endDate} currentDate={currentDate} changeDate={changeDate} width={width} ticketNumber={ticketNumber} setTicketNumber={setTicketNumber} handleTicketNumber={handleTicketNumber} memberShipType={dropdownValue} />
                        ) : null}
                        <div className='mt-6'>
                            <div className='text-[#434343] mb-2'>お名前（仮）</div>
                            <input
                                onChange={(e) => setName(e.target.value)}
                                type="text" placeholder='お名前' className={`
                                ${width < 768 ? 'w-full' : 'w-1/5'} 
                                border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none`} />
                        </div>

                        <div className='flex justify-start mt-6'>
                            <button
                                onClick={() => { inviteUser() }}
                                className='bg-[#2F54EB] text-white rounded-[2px] px-10 py-2'>追加</button>
                            <button
                                onClick={() => { navigate(-1) }}
                                className='text-[#2F54EB] rounded-[2px] px-4 py-2 mr-2'>キャンセル</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InviteUserPage;
