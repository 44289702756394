import React from 'react'

export default function EmailChange() {
    return (
        <div className='min-h-[calc(100vh-150px)] w-full text-center flex items-center justify-center flex-col mx-auto mb-24'>
            <svg
                width='70'
                height='70'
                viewBox='0 0 70 70'
                fill='none'
                className='mx-auto mb-5'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.131 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4688 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1562 50.1172 23.5703Z'
                    fill='#52C41A'
                />
            </svg>
            <div>
                <div className='text-[#141414] mt-2'>メールアドレスを変更しました
                </div>
                <div className='text-[#434343] mt-8 max-w-xl'>
                    メールアドレスの確認用リンクが新しいメールアドレス先に届きます。メールを開いてリンクをクリックしてください。
                </div>
            </div>
        </div>
    )
}
