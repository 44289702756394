import React from 'react'
import Lock from '../assets/lock.png'

const CalendarPreview = ({ start_time, numberOfUsers, isDefaultClose }) => {
    return (
        <>
            {isDefaultClose ? (
                <div className='flex items-center justify-center min-h-[143px] text-center p-3 bg-[#FFF]'>
                </div>
            ) : (
                <div className={`flex items-center justify-center min-h-[143px] text-center p-3 
            ${numberOfUsers > 0 ? 'bg-[#FFF]' : 'bg-[#F0F5FF]'}
            border border-[#ADC6FF]`}>
                    <div className='flex flex-col items-center justify-center gap-5'>
                        <p className='text-[#434343] font-medium'>{start_time}</p>
                        {numberOfUsers > 0 ?
                            <>
                                <div className='flex items-center justify-center'>
                                    {Array.apply(null, Array(Number(numberOfUsers))).map((_, i) => (
                                        <div
                                            key={i}
                                            className={`w-4 h-4 rounded-full bg-[#FFF] border border-[#434343]`}
                                        />
                                    ))}
                                </div>
                            </>
                            :
                            <img
                                src={Lock}
                                alt='lock'
                                className='w-4 h-4'
                            />
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default CalendarPreview
