import { useState, useEffect, useCallback, useContext } from 'react'
import Header from '../admin-components/Header'
import { Link, useLocation } from 'react-router-dom'
import CalenderComponent from '../components/Calender'
import PreviewCalenderComponent from '../components/PreviewCalendar'
import axios from 'axios'
import UserCalenderStatus from '../admin-components/User-Calendar-Status'
import Toast from '../utils/Toast/Toast'
import useWindowDimensions from '../utils/screenDimensions'
import { AdminContext } from '../context/adminContext'
import moment from 'moment'

const Calendar = () => {
  const { width } = useWindowDimensions()
  const [setconfirmation] = useState(false)
  const [finish, setfinish] = useState(false)
  const location = useLocation()
  const [tab, settab] = useState(1)
  const [isModify, setisModify] = useState(false)
  const [calendar, setcalendar] = useState([])
  const [flashActive, setFlashActive] = useState(false)
  const [flashMessage, setFlashMessage] = useState('')
  const [flashType, setFlashType] = useState('')
  const [preveiw, setpreveiw] = useState(false)
  const [timer] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [todaysDate, setTodaysDate] = useState(moment().format('YYYY-MM-DD'));
  const [date, setDate] = useState(0);
  const { getAllUsers, calendarHistories, getCalendarHistories } = useContext(AdminContext)
  const [week, setweek] = useState(0)

  useEffect(() => {
    getAllUsers();
  }, []);

  const hideToast = () => {
    setFlashActive(false);
  };

  const handleShowToast = (e) => {
    hideToast();
    if (flashMessage) {
      setFlashActive(true);
      window.setTimeout(hideToast, timer);
    }
  };

  const updateTimeSlots = useCallback(async (date, text) => {
    try {
      const res = await axios.put('/update-timeSlot', {
        calendar,
        date,
        day: text,
      })
      if (res.data) {
        setcalendar(res.data.calendar)
      }
      setweek(week + 1)
      getCalendarHistories()
    } catch (error) {
      console.warn(error)
    }
  }, [calendar, getCalendarHistories, week])

  const updateDate = async (e) => {
    try {
      setLoading(true)
      const res = await axios.put('/calendar', {
        data: calendar,
      })
      if (res.data) {
        setFlashMessage('予約を変更しました。')
        setFlashType('success')
        handleShowToast()
        setpreveiw(false)
        setisModify(false)
        setLoading(false)
        setweek(week + 1)
        getCalendarHistories()
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const changeWeek = (e) => {
    if (isModify) {
      setFlashMessage('予約を変更中です。')
      setFlashType('error')
      handleShowToast()
      return
    }
    if (e === 'next') {
      setweek(week + 1)
      getCalendar(week + 1)
    } else {
      setweek(week - 1)
      getCalendar(week - 1)
    }
  }

  const getCalendar = useCallback(async (number) => {
    try {
      setLoading(true)
      // const number = width > 768 ? week : todaysDate
      const res = await axios.get(`/calendar/${number}`)
      if (res.data) {
        setcalendar(res.data.calendar)
        setIsPublished(res.data.calendar[0].isPublished)
        getCalendarHistories()
      }
      setLoading(false)
    } catch (error) {
      console.warn(error)
    }
  }, [getCalendarHistories])

  useEffect(() => {
    if (location.state && location.state.week !== undefined) {
      getCalendar(location.state.week);
      setweek(location.state.week);
      // remove week from location state
      location.state.week = undefined;
    } else {
      getCalendar();
    }
  }, []);

  // const onSelected = (e) => { }

  const changeDate = (e) => {
    if (isModify) {
      setFlashMessage('予約を変更中です。')
      setFlashType('error')
      handleShowToast()
      return
    }
    if (e === 'next') {
      setTodaysDate(moment(todaysDate).add(1, 'days').format('YYYY-MM-DD'))
      const index = calendar.findIndex((item) => item.date === todaysDate)
      if (index === 6) {
        setDate(date + 1)
        getCalendar(date + 1)
      }
    } else {
      setTodaysDate(moment(todaysDate).subtract(1, 'days').format('YYYY-MM-DD'))
      const index = calendar.findIndex((item) => item.date === todaysDate)
      if (index === 0) {
        setDate(date - 1)
        getCalendar(date - 1)
      }
    }
  }

  return (
    <div>
      {flashActive && (
        <Toast
          type={flashType}
          message={flashMessage}
          duration={3000}
          active={flashActive}
          setActive={setFlashActive}
          position={'tright'}
          width={'small'}
        />
      )}
      <Header type='calendar' />
      {finish === false ? (
        <div className='w-full h-[calc(100vh-74px)] overflow-auto mb-10'>
          <div>
            <div className='bg-white px-5 pt-5'>
              <div className='flex items-center justify-between w-full mb-8 3sm:mb-6'>
                <div
                  className='text-2xl font-bold flex items-center text-[#000] gap-2 flex-row-reverse'
                >
                  予約管理
                </div>
              </div>
              <div className='bg-white p-2 pb-0'>
                <div className='flex gap-7 mt-3 whitespace-nowrap  overflow-x-auto'>
                  <button
                    onClick={() => {
                      settab(1)
                    }}
                    className={`pb-2 ${tab === 1
                      ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                      : 'text-[#434343]'
                      }`}
                  >
                    予約状況
                  </button>
                  <button
                    onClick={() => {
                      settab(2)
                    }}
                    className={`pb-2 ${tab === 2
                      ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                      : 'text-[#434343]'
                      }`}
                  >
                    変更履歴
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='p-5 h-screen'>
            {tab === 1 ? (
              preveiw ? (
                <div className='bg-white p-2 mb-10'>
                  <div className='flex items-center justify-between w-full mb-16 3sm:mb-6'>
                    <button
                      onClick={() => {
                        setpreveiw(false)
                        setisModify(true)
                      }}
                      className={`flex items-center text-[#2F54EB] gap-2 flex-row-reverse cursor-pointer`}
                    >
                      編集に戻る
                    </button>
                    <div className='4sm:text-[18px] text-[#434343] -mb-20 3sm:mb-0'>
                      {width > 768 ?
                        calendar[0]?.firstAndLastDayInJapanese :
                        calendar?.map((item) => {
                          return (
                            (item.date === todaysDate) && item.singlejapaneseday
                          )
                        })
                      }
                    </div>
                    <div
                      onClick={() => {
                        updateDate()
                      }}
                      className='flex items-end justify-end text-[#fff] bg-blue-600 px-12 py-2 cursor-pointer'>
                      公開
                    </div>
                  </div>
                  <PreviewCalenderComponent calendar={calendar} todaysDate={todaysDate} />
                </div>
              ) : (
                <div className='bg-white p-5 mb-10'>
                  <div className='flex items-center justify-between w-full mb-16 3sm:mb-6'>
                    <button
                      onClick={() => {
                        if (width > 768) {
                          changeWeek('prev')
                        } else {
                          changeDate('prev')
                        }
                      }}
                      className={`flex items-center relative
                    ${isModify ?
                          'text-[#434343]' :
                          'text-[#2F54EB]'
                        }
                    gap-2 flex-row-reverse`}
                    >
                      {width > 768 ? isModify ? '' : '先週' : isModify ? '' : '前日'}
                      {!isModify && (
                        <svg
                          width='10'
                          height='15'
                          viewBox='0 0 7 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6.8987 1.98424V0.927406C6.8987 0.835805 6.79343 0.785219 6.72233 0.841273L0.559053 5.65514C0.506687 5.69586 0.464315 5.74801 0.435168 5.8076C0.406021 5.86719 0.390869 5.93265 0.390869 5.99899C0.390869 6.06532 0.406021 6.13079 0.435168 6.19038C0.464315 6.24997 0.506687 6.30211 0.559053 6.34284L6.72233 11.1567C6.7948 11.2128 6.8987 11.1622 6.8987 11.0706V10.0137C6.8987 9.94674 6.86726 9.88248 6.8153 9.84147L1.89343 5.99967L6.8153 2.15651C6.86726 2.11549 6.8987 2.05123 6.8987 1.98424V1.98424Z'
                            fill={
                              isModify ?
                                "#434343" :
                                "#2F54EB"
                            }
                          />
                        </svg>
                      )}
                    </button>
                    <div className='4sm:text-[18px] text-[#434343] -mb-20 3sm:mb-0'>
                      {width > 768 ?
                        calendar[0]?.firstAndLastDayInJapanese :
                        calendar?.map((item) => {
                          return (
                            (item.date === todaysDate) && item.singlejapaneseday
                          )
                        })
                      }
                    </div>
                    <button
                      onClick={() => {
                        if (width > 768) {
                          changeWeek('next')
                        } else {
                          changeDate('next')
                        }
                      }}
                      className={`flex items-center relative
                    ${isModify ?
                          'text-[#434343]' :
                          'text-[#2F54EB]'
                        }
                    gap-2`}
                    >
                      {width > 768 ? isModify ? '' : '来週' : isModify ? '' : '翌日'}
                      {!isModify && (
                        <svg
                          width='10'
                          height='15'
                          viewBox='0 0 9 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.45949 6.55692L0.535291 0.367666C0.514585 0.351365 0.489701 0.341234 0.463496 0.338438C0.437292 0.335641 0.41083 0.340292 0.38715 0.351857C0.36347 0.363422 0.343532 0.381431 0.329626 0.403816C0.31572 0.426202 0.308409 0.452056 0.308534 0.478409V1.8372C0.308534 1.92333 0.348964 2.00595 0.415761 2.05868L6.74387 6.99989L0.415761 11.9411C0.347206 11.9938 0.308534 12.0765 0.308534 12.1626V13.5214C0.308534 13.6392 0.443885 13.7042 0.535291 13.6321L8.45949 7.44286C8.52684 7.39033 8.58132 7.32312 8.6188 7.24636C8.65627 7.1696 8.67575 7.08531 8.67575 6.99989C8.67575 6.91448 8.65627 6.83018 8.6188 6.75342C8.58132 6.67666 8.52684 6.60946 8.45949 6.55692Z'
                            fill={
                              isModify ?
                                "#434343" :
                                "#2F54EB"
                            }
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                  <div className='flex items-end justify-end w-full cursor-pointer'>
                    <div
                      onClick={() => {
                        if (isModify) {
                          setisModify(false)
                          setpreveiw(true)
                        } else {
                          setisModify(true)
                        }
                      }}
                      className='items-end justify-end'>
                      <div className='flex items-end justify-end text-[#fff] w-full sm:w-full bg-blue-600 p-2'>
                        {isModify ? 'プレビュー' : '新規スケジュールを作成'}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className='flex items-center justify-center h-[calc(100vh-150px)]'>
                      <div className='w-10 h-10 border-4 border-t-4 border-gray-500 rounded-full animate-spin'></div>
                    </div>
                  ) : (
                    <CalenderComponent week={week} isModify={isModify} calendar={calendar} setcalendar={setcalendar} setisModify={setisModify} isPublished={isPublished} setIsPublished={setIsPublished} updateTimeSlots={updateTimeSlots} todaysDate={todaysDate} />
                  )}
                </div>
              )
            ) : (
              <div className='bg-white p-5'>
                <div className='w-full border-b border-[#F0F0F0] pb-4'>
                  <div className='text-[#434343] text-[20px] mb-0.5'>
                    変更履歴
                  </div>
                </div>
                <div className='overflow-auto overflow-x-auto'>
                  <UserCalenderStatus histories={calendarHistories} />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className='min-h-[calc(100vh-150px)] max-w-[300px] text-center flex items-center justify-center flex-col mx-auto mb-24 h-screen'>
            <div>
              <svg
                width='70'
                height='70'
                viewBox='0 0 70 70'
                fill='none'
                className='mx-auto mb-5'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.131 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4688 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1562 50.1172 23.5703Z'
                  fill='#52C41A'
                />
              </svg>
              <div className='text-[#141414]'>予約が完了いたしました</div>
            </div>
            <button
              onClick={() => {
                setconfirmation(false)
                setfinish(false)
              }}
              className='w-full max-w-[150px] flex items-center justify-center mx-auto bg-white py-2.5 mt-16 border border-[#2F54EB] text-[#2F54EB] rounded-[2px]'
            >
              マイページへ
            </button>
          </div>
          <div className='absolute bottom-3 left-0 w-full flex items-center justify-center flex-col gap-2 px-5'>
            <div className='flex gap-6'>
              <Link to='/' className='text-[#2F54EB]'>
                ホームページ
              </Link>
              <Link to='/' className='text-[#2F54EB]'>
                お問い合せ
              </Link>
            </div>
            <div className='text-center text-[#434343]'>
              Copyright (C) DTS KICKBOXING GYM
              {new Date().getFullYear()}
              All Rights Reserved
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Calendar
