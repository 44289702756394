import React, { memo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
registerLocale('ja', ja);

const EditDate = ({ width, handleTicketNumber, ticketNumber, changeDate, currentDate, endDate, memberShipType, dropdownValue }) => {
    return (
        <div className={`flex 
                            ${width > 768 ? 'flex-row' : 'flex-col'}
                            p-5 bg-[#F0F5FF] w-fit mt-6 overflow-auto overflow-x-auto`}>
            <div>
                <div className='text-[#434343] mb-2'>枚数</div>
                <input
                    onChange={handleTicketNumber}
                    type='number'
                    value={ticketNumber || ''}
                    placeholder="枚数を入力"
                    min={1}
                    className='outline-[#ADC6FF] bg-white px-2 py-1.5 max-w-[100px]'
                />
            </div>
            <div className={`${width > 768 ? 'ml-6' : 'mt-6'}`}>
                <div className='text-[#434343] mb-2'>購入日</div>
                <DatePicker
                    onChange={(date) => changeDate(date, 'currentDate')}
                    selected={currentDate ? new Date(currentDate) : null}
                    locale='ja'
                    dateFormat='yyyy-MM-dd'
                    className='outline-[#ADC6FF] bg-white px-2 py-1.5 max-w-[250px]'
                />
            </div>
            <div className={`${width > 768 ? 'ml-6' : 'mt-6'}`}>
                <div className='text-[#434343] mb-2'>
                    有効期間
                    {memberShipType === '回数券会員' || dropdownValue === '回数券会員' ? '(三ヶ月)' : '(10日間)'}
                </div>
                <DatePicker
                    onChange={(date) => changeDate(date, 'endDate')}
                    selected={endDate ? new Date(endDate) : null}
                    locale='ja'
                    dateFormat='yyyy-MM-dd'
                    className='outline-[#ADC6FF] bg-white px-2 py-1.5 max-w-[250px]'
                />
            </div>
            <div className={`${width > 768 ? 'ml-6' : 'mt-6 ml-1'} flex items-end`}>
                <div className='text-[#434343] mb-2'>まで有効</div>
            </div>
        </div>
    );
};

export default memo(EditDate);