const handleEmailValidation = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

const checkKanjis = (kanji) => {
    const regex = /[\u4e00-\u9faf]/;
    return regex.test(String(kanji));
}

const checkHiraganas = (hiragana) => {
    const regex = /[\u3040-\u309f]/;
    return regex.test(String(hiragana));
}

export {handleEmailValidation, checkKanjis, checkHiraganas};