import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../admin-components/Header';
import useWindowDimensions from '../utils/screenDimensions';
import axios from 'axios';
import Dropdown from '../admin-components/Dropdown';
import TextField from '../admin-components/Text-Field';
import BackButton from '../admin-components/BackButton';

const InviteUserEdit = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdown, setdropdown] = useState(false);
    const [dropdownValue, setdropdownValue] = useState('月4回会員');
    const [kanjiName, setKanjiName] = useState('');
    const [email, setEmail] = useState('');
    const { width } = useWindowDimensions();

    const getUser = useCallback(async () => {
        try {
            const { data } = await axios.get(`/admin/get-user/${location?.state?.email}`)
            setKanjiName(data.user?.kanjiName)
            setEmail(data.user?.email)
            setdropdownValue(data.user?.memberShipType)
        }
        catch (error) {
            window.location.href = "/admin/login";
            console.log(error)
        }
    }, [location?.state?.email])

    useEffect(() => {
        getUser()
    }, [getUser])

    const updateUserInfo = async () => {
        try {
            const { data } = await axios.post('/admin/update-user', {
                kanjiName,
                email,
                memberShipType: dropdownValue
            })
            if (data.message) {
                navigate(`/admin/account-managment`, {
                    state: {
                        tab: 3,
                    }
                })
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='w-full h-screen mb-10'>
            <Header type='management' />
            <div className='w-full h-screen'>
                <div className='p-5'>
                    <BackButton onClick={() => {
                        navigate(-1)
                    }} />
                    <div className='bg-white p-5  border-b border-[#F0F0F0] pb-4 mb-6'>
                        <div className='border-b border-[#F0F0F0] pb-4 mb-6'>
                            <div className='w-full text-[#434343] font-semibold'>
                                招待情報変更
                            </div>
                        </div>


                        <div className='mt-6 mb-6'>
                            <div className='flex items-center gap-2 mb-2'>
                                <div className='text-[#434343]'>メールアドレス</div>
                                <div className='content-[#ff4d4d] text-[#f24c4c]'>*</div>
                            </div>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                                value={email ? email : ''}
                                type="text" placeholder='メールアドレス' className={`
                                ${width < 768 ? 'w-full' : 'w-1/5'}
                                bg-[#D9D9D9] placeholder-[#D9D9D9] text-[#8C8C8C] rounded-[2px] px-2 py-2 outline-none`} />
                        </div>

                        <Dropdown dropdownValue={dropdownValue} setdropdownValue={setdropdownValue} dropdown={dropdown} setdropdown={setdropdown} width={width} />

                        <TextField input={kanjiName} type={'text'} setInput={setKanjiName} label='お名前（漢字）' placeholder='お名前' />

                        <div className='flex justify-start items-center gap-8 mt-6'>
                            <button
                                onClick={() => {
                                    updateUserInfo()
                                }}
                                className='flex items-center text-white gap-2 flex-row-reverse bg-[#2F54EB] px-10 py-2'
                            >
                                変更を保存
                            </button>
                            <button
                                onClick={() => {
                                    navigate(-1)
                                }}
                                className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse'
                            >
                                キャンセル
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InviteUserEdit;
