import CalendarPreview from '../admin-components/PreviewUser'
import useWindowDimensions from '../utils/screenDimensions'

const PreviewCalenderComponent = ({ calendar, todaysDate }) => {
    const { width } = useWindowDimensions()

    return (
        width > 768 ? (
            <div className="grid grid-cols-1 sm:grid-cols-7 w-full mt-7">
                {calendar.map((day, index) => (
                    <div key={index} className="col-span-1 text-[#434343]">
                        <div key={index} className={`hidden sm:flex items-center justify-center text-[#434343] mb-4`}>
                            {day.japanesedate}
                        </div>
                    </div>
                ))}
                {calendar.map((day, index) => (
                    <div key={index} className="col-span-1 text-[#434343]">
                        {day.timeSlots.map((time, index) => (
                            day.isOpen ?
                                <CalendarPreview key={index} start_time={time.start_time} numberOfUsers={time?.defaultSeats} isDefaultClose={time?.isDefaultClose} />
                                : (
                                    <div key={index}
                                        className='flex items-center justify-center min-h-[143px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                        <div
                                            key={index}
                                            className='flex flex-col items-center justify-center gap-5'>
                                            {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                        </div>
                                    </div>
                                )
                        ))}
                    </div>
                ))}
            </div>
        ) : (
            <div className="grid grid-cols-1 sm:grid-cols-7 w-full mt-7">
                {calendar.map((day, index) => (
                    (day.date === todaysDate) && (
                        <div key={index} className="text-[#434343] mb-4">
                            <div key={index} className={`hidden sm:flex items-center justify-center text-[#434343] mb-4`}>
                                {day.japanesedate}
                            </div>
                            {day.timeSlots.map((time, index) => (
                                day.isOpen ?
                                    <CalendarPreview key={index} start_time={time.start_time} numberOfUsers={time?.defaultSeats} isDefaultClose={time?.isDefaultClose} />
                                    : (
                                        <div key={index}
                                            className='flex items-center justify-center min-h-[143px] text-center p-3 bg-[#F0F5FF] border-l-2 b-t-2 border-[#ADC6FF]'>
                                            <div
                                                key={index}
                                                className='flex flex-col items-center justify-center gap-5'>
                                                {index === 0 && <p className='text-[#434343] font-medium'>定休日</p>}
                                            </div>
                                        </div>
                                    )
                            ))}
                        </div>
                    )))}
            </div>
        )
    )
}

export default PreviewCalenderComponent