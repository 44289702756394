import { Table } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const CurrentUserTable = ({ users, callback, onDelete, onSendEmailMultiple, onSendEmail }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: '会員タイプ',
            dataIndex: 'memberShipType',
            sorter: (a, b) => a.memberShipType.localeCompare(b.memberShipType),
        },
        {
            title: 'お名前',
            dataIndex: 'kanjiName',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'アクション',
            dataIndex: 'action',
            key: 'x',
            render: (text, record) => {
                return (
                    <div className='flex flex-row gap-4' key={record._id}>
                        <p
                            onClick={() => onDelete(record.email)}
                            className='text-red-500 font-normal cursor-pointer'>
                            招待をキャンセル
                        </p>
                        <div className='border-r-2 border-gray-300 h-5'></div>
                        <div
                            onClick={() => onSendEmail(record.email)}
                            className='text-blue-500 font-normal cursor-pointer'>
                            再送信
                        </div>
                        <div className='border-r-2 border-gray-300 h-5'></div>
                        <Link to='/admin/invite-user-edit'
                            state={{ email: record?.email }}
                            className='text-blue-500 font-normal'>会員種類変更</Link>
                    </div>
                );
            }
        }
    ];
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        callback(newSelectedRowKeys);
        onSendEmailMultiple(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return <Table rowSelection={rowSelection} columns={columns} dataSource={users} />;
};
export default CurrentUserTable;