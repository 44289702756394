import { Table } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const UserApplied = ({ users, callback, onDelete }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const columns = [
        {
            title: 'お名前',
            dataIndex: 'kanjiName',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: '会員タイプ',
            dataIndex: 'memberShipType',
        },
        {
            title: '登録日',
            dataIndex: 'createdAt',
            render: (text) => {
                return moment(text).format('YYYY/MM/DD');
            },
        },
        {
            title: 'アクション',
            dataIndex: 'action',
            key: 'x',
            render: (text, record) => {
                return (
                    <div className='flex flex-row gap-4'>
                        <p
                            onClick={() => onDelete(record.email)}
                            className='text-red-500 font-normal cursor-pointer'>申請を削除</p>
                        <div className='border-r-2 border-gray-300 h-5'></div>
                        <Link
                            to='/admin/applied-user-action'
                            state={{ email: record?.email }}
                            className='text-blue-500 font-normal'>
                            詳細を見る
                        </Link>
                    </div>
                );
            }
        }
    ];
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        callback(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return <Table rowSelection={rowSelection} columns={columns} dataSource={users} />;
};
export default UserApplied;