import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import UserInvited from './user-pages/UserInvited'
import Register from './user-pages/Register'
import Dashboard from './user-pages/Dashboard'
import Calendar from './user-pages/Calendar'
import AccountManagment from './user-pages/Account-Managment'
import Login from './admin-pages/Login'
import AdminCalendar from './admin-pages/Calendar'
import CalenderEdit from './admin-pages/Calender-Edit'
import AccountManagmentView from './admin-pages/MemberShip-Management'
import InviteUserPage from './admin-pages/InviteUser'
import EditUser from './admin-pages/Edit-User'
import AppliedUserAction from './admin-pages/AppliedUserAction'
import InviteUserEdit from './admin-pages/Invite-User-Edit'
import UserApplication from './admin-pages/UserApplication'
import NotFound from './Not-Found'
import EmailChange from './user-pages/emailChange'
import ForgotPassword from './user-pages/ForgotPassword'
import { UserProvider } from './context/userContext'
import { AdminProvider } from './context/adminContext'

const AuthRoutes = () => {
    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route role="user" path="/user/*">
                        <Route path="" element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="account-managment" element={<AccountManagment />} />
                        <Route path="email-change" element={<EmailChange />} />
                    </Route>

                    <Route exact path="/user-invited" element={<UserInvited />} />
                    <Route exact path="/auth" element={<Register />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/" element={<Register />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </UserProvider>
    )
}

const AdminRoutes = () => {
    return (
        <AdminProvider>
            <Router>
                <Routes>
                    <Route role="admin" path="/admin/*">
                        <Route path="login" element={<Login />} />
                        <Route path="dashboard" element={<AdminCalendar />} />
                        <Route path="calendar-edit/*" element={<CalenderEdit />} />
                        <Route path="account-managment" element={<AccountManagmentView />} />
                        <Route path="invite-user" element={<InviteUserPage />} />
                        <Route path="edit-user" element={<EditUser />} />
                        <Route path="applied-user-action" element={<AppliedUserAction />} />
                        <Route path="invite-user-edit" element={<InviteUserEdit />} />
                        <Route path="user-application" element={<UserApplication />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router >
        </AdminProvider>
    )
}

export { AuthRoutes, AdminRoutes }
