import React from 'react';

const TextField = ({ input, setInput, width, label, type, placeholder }) => {
    return (
        <div className='mt-6'>
            <div className='text-[#434343] mb-2'>{label}</div>
            <input
                onChange={(e) => setInput(e.target.value)}
                value={input}
                type={type} 
                placeholder={placeholder} 
                className={`
                    ${width < 768 ? 'w-full' : 'w-1/5'}
                    border border-[#ADC6FF] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none`} />
        </div>
    );
}

export default TextField;
