import React, { useState } from 'react'
import axios from 'axios'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [showModal, setshowModal] = useState(false)

    const resetPassword = async () => {
        try {
            const { data } = await axios.put(`/user/change-password-to-temp/${email}`);
            if (data.message) {
                setshowModal(true)
            }
        } catch (error) {
            console.warn(error)
        }
    }

    return (
        <>
            <div className='relative min-h-screen p-5 flex items-center bg-white'>
                <div className='max-w-[300px] flex items-center justify-center flex-col mx-auto mt-10 mb-24'>
                    {showModal === false ? (
                        <>
                            <div>
                                <img src='/images/logo.svg' alt='logo' />
                            </div>
                            <div className='mt-20'>
                                <div className='text-[#141414]'>
                                    メールアドレスを入力してください、仮のパスワードを発行します。
                                </div>
                                <div className='mt-7'>
                                    <div>
                                        <div className='text-[#434343] text-[16px] mb-2'>登録したメールアドレス</div>
                                        <input
                                            type='email'
                                            value={email || ''}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder='メールアドレス'
                                            className={`w-full border border-[#ADC6FF] text-[14px] bg-white placeholder-[#D9D9D9] text-[#141414] rounded-[2px] px-2 py-2 outline-none`}
                                        />
                                    </div>

                                    <button
                                        onClick={() => {
                                            resetPassword()
                                        }}
                                        className={`w-full max-w-[150px] flex items-center justify-center mx-auto 
                                ${!email ? 'bg-[#D9D9D9]' : 'bg-[#2F54EB]'}
                                ${!email ? 'cursor-not-allowed' : 'cursor-pointer'}
                                py-2.5 
                                mt-7 
                                ${!email ? 'text-[#8C8C8C]' : 'text-[#FFF]'}
                                rounded-[2px]`}
                                    >
                                        確認
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='w-full text-center flex items-center justify-center flex-col mx-auto'>
                                <div>
                                    <svg
                                        width='70'
                                        height='70'
                                        viewBox='0 0 70 70'
                                        fill='none'
                                        className='mx-auto mb-5'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d="M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM50.1172 23.5703L33.6641 46.3828C33.4341 46.7038 33.1309 46.9653 32.7797 47.1457C32.4285 47.3261 32.0394 47.4203 31.6445 47.4203C31.2497 47.4203 30.8605 47.3261 30.5093 47.1457C30.1581 46.9653 29.855 46.7038 29.625 46.3828L19.8828 32.8828C19.5859 32.4687 19.8828 31.8906 20.3906 31.8906H24.0547C24.8516 31.8906 25.6094 32.2734 26.0781 32.9297L31.6406 40.6484L43.9219 23.6172C44.3906 22.9687 45.1406 22.5781 45.9453 22.5781H49.6094C50.1172 22.5781 50.4141 23.1563 50.1172 23.5703V23.5703Z"
                                            fill="#52C41A"
                                        />
                                    </svg>
                                    <div className='text-[#141414] text-[20px] mt-4'>仮のパスワードを発行しました。メールボックスをご確認ください。</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className='absolute bottom-3 left-0 w-full flex items-center justify-center flex-col gap-2 px-5'>

                    <div className='text-center text-[#434343]'>
                        Copyright (C) DTS KICKBOXING GYM
                        {' ' + new Date().getFullYear() + ' '}
                        All Rights Reserved
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
