import React from 'react'

const CalendarBlankComp = ({day}) => {
    return (
        <>
            <div className='flex flex-col justify-between items-center min-h-[143px] text-center p-3 bg-[#FFF]'>
            </div>
        </>
    )
}

export default CalendarBlankComp
