import React, { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";
import useWindowDimensions from '../utils/screenDimensions'
import moment from "moment";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [calendar, setcalendar] = useState(null);
    const { width } = useWindowDimensions();
    const [currentWeek, setcurrentWeek] = useState(null);

    const getCalendar = useCallback(async (week) => {
        try {
            if (width > 768) {
                const { data } = await axios.get(`/user/calendar/${week}`)
                setcalendar(data.weekCalendar)
                setcurrentWeek(data.firstAndLastDayInJapanese)
            } else {
                const { data } = await axios.get(`/user/day-calendar/${week}`)
                setcalendar([data.dayCalendar])
            }
        } catch (error) {
            return 0;
        }
    }, [width])

    useEffect(() => {
        async function getUser() {
            try {
                const res = await axios.get("/user/profile");
                res.data.user.bookings.sort((a, b) => {
                    const dateA = moment(a.date, 'YYYY年MM月DD日').format('YYYY-MM-DD')
                    const dateB = moment(b.date, 'YYYY年MM月DD日').format('YYYY-MM-DD')
                    return dateA > dateB ? 1 : -1
                })
                setUser(res.data.user);
            } catch (err) {
                return;
            }
        }
        getUser();
    }, []);

    return (
        <UserContext.Provider value={
            {
                user,
                setUser,
                calendar,
                setcalendar,
                getCalendar,
                currentWeek,
                setcurrentWeek
            }
        }>
            {children}
        </UserContext.Provider>
    );
}