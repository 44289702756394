import { Table } from 'antd';
import moment from 'moment';
const { Column } = Table;
const UserStatus = ({ data }) => {
    return (
        <Table
            dataSource={data}
        >
            <Column
                title='登録日'
                dataIndex='date'
                render={(text) => {
                    return moment(text).format('YYYY/MM/DD');
                }}
            />
            <Column
                title='種類'
                dataIndex='type'
            />
            <Column
                title='詳細'
                dataIndex='body'
            />
        </Table>
    )
};
export default UserStatus;