import React from 'react';

const NotFound = () => {
    return (
        <div className='flex justify-center items-center h-screen'>
            <div className='text-center'>
                <h1 className='text-8xl font-bold mb-4 text-[#2F54EB]'>404</h1>
                <h1 className='text-4xl font-bold mb-8 text-[#2F54EB]'>PAGE NOT FOUND</h1>
                <h2 className='text-2xl font-medium'>お探しのページが見つかりませんでした。</h2>
                <p className='text-lg font-medium mt-4'>
                    URLが変更・削除されたか、現在ご利用できない可能性があります。<br />
                    お手数ですが、メニューバーから目的のページをお探しください。
                </p>
            </div>
        </div>
    );
}

export default NotFound;
