import axios from 'axios'
import { AdminRoutes, AuthRoutes } from './routes'

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.withCredentials = true
  return (
    <div>
      {window.location.pathname.includes('admin') ? <AdminRoutes /> : <AuthRoutes />}
    </div>
  )
}

export default App
