import React, { useState, useCallback, useEffect } from 'react';
import Lock from '../assets/lock.png';
import moment from 'moment';

const CalendarEdit = ({
    openToggle,
    data, date,
    users,
    calenderData,
    totalBookings,
    setcalenderData,
    // addBooking,
    // removeBooking,
    canceledSlots,
    setcanceledSlots,
    removeAllState,
    usersToBeAdded,
    setUsersToBeAdded,
    setUsersToBeRemoved,
    usersToBeRemoved,
    toastError,
}) => {
    const [currentMember, setCurrentMember] = useState(totalBookings);
    const [maxMember, setMaxMember] = useState(data?.defaultSeats || Number);
    const [onClicked, setOnClicked] = useState(false);
    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState([]);


    const ifUserCanBook = useCallback((user) => {
        if (user?.memberShipType === '月12回会員') {
            if (user?.bookings?.length + user?.ticketDetails?.length === 3) {
                toastError('月12回会員は3回まで予約できます。', 'error');
                return false
            }
        }
        if (user?.memberShipType === '月8回会員') {
            if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
                toastError('月8回会員は1回まで予約できます。', 'error');
                return false
            }
        }
        if (user?.memberShipType === '月4回会員') {
            if (user?.bookings?.length + user?.ticketDetails?.length === 1) {
                toastError('月4回会員会員は1回まで予約できます。', 'error');
                return false
            }
        }
        if (user?.memberShipType === '回数券会員') {
            if (user?.ticketDetails?.length === 1) {
                toastError('回数券会員は1回まで予約できます。', 'error');
                return false
            }
        }
        if (user?.bookings?.some((booking) => booking?.date === calenderData?.singlejapaneseday)) {
            toastError('この日は既に予約されています。', 'error');
            return false
        }
        if (user?.ticketDetails?.some((ticket) => ticket?.date === calenderData?.singlejapaneseday)) {
            toastError('この日は既に予約されています。', 'error');
            return false
        }
        if (data?.members?.some((member) => member?.email === user?.email)) {
            toastError('この日は既に予約されています。', 'error');
            return false
        }
        if (usersToBeAdded?.some((member) => member?.email === user?.email)) {
            toastError('この日は既に予約されています。', 'error');
            return false
        }
        return true
    }, [data, calenderData, usersToBeAdded, toastError]);

    const handleAddMember = useCallback((item) => {
        const isUserAlreadyAdded = usersToBeAdded.some((user) => user.email === item?.email);
        if (isUserAlreadyAdded) {
            toastError('この日は既に予約されています。', 'error');
            return;
        }

        // Checks if the current member is equal to the maximum member
        if (currentMember === maxMember) {
            toastError('この日は既に予約されています。', 'error');
            return;
        }

        // Checks if the user is already booked
        const isUsersAlreadyBooked = ifUserCanBook(item);
        if (!isUsersAlreadyBooked) {
            return;
        }

        // Adds the user to the list of users to be added
        const newUserObject = {
            day: calenderData?.singlejapaneseday,
            time: data?.start_time,
            email: item?.email,
        };
        setUsersToBeAdded(prevState => [...prevState, newUserObject]);

        // Adds the user to the list of members
        data.members.push({
            email: item?.email,
            name: item?.kanjiName,
            key: item?.key,
        });

        // Checks if the user is already removed
        const isUserAlreadyRemoved = usersToBeRemoved.find((user) => user.email === item?.email);
        if (isUserAlreadyRemoved) {
            // Removes the user from the list of users to be removed
            const newUsers = usersToBeRemoved.filter((user) => user.email !== item?.email);
            setUsersToBeRemoved(newUsers);
        }

        // Resets the search
        setSearch('');
        setSearchResult([]);
    }, [currentMember, maxMember, usersToBeAdded, usersToBeRemoved, data, calenderData, ifUserCanBook, setUsersToBeRemoved, setUsersToBeAdded, toastError]);

    const handleRemoveMember = useCallback(async (item) => {
        const getBooking = data?.members.find((member) => member.email === item?.email);
        if (getBooking) {
            const newMembers = data?.members.filter((member) => member.email !== item?.email);
            data.members = newMembers;
            const isUserAlreadyAdded = usersToBeAdded.find((user) => user.email === item?.email);
            if (isUserAlreadyAdded) {
                const newUsers = usersToBeAdded.filter((user) => user.email !== item?.email);
                setUsersToBeAdded(newUsers);
            } else {
                const newObject = {
                    day: calenderData?.singlejapaneseday,
                    email: item?.email,
                    time: data?.start_time,
                };
                if (usersToBeRemoved.length === 0) {
                    setUsersToBeRemoved([newObject]);
                } else {
                    setUsersToBeRemoved([...usersToBeRemoved, newObject]);
                }
            }
            return;
        }
    }, [usersToBeAdded, usersToBeRemoved, data, calenderData, setUsersToBeAdded, setUsersToBeRemoved]);

    useEffect(() => {
        setCurrentMember(totalBookings);
    }, [currentMember, totalBookings, handleAddMember, handleRemoveMember]);
    const handleToggle = (item) => {
        const currentTime = moment().format('HH:mm');
        const currentDate = moment().format('YYYY-MM-DD');
        if (moment(currentDate).isSame(date) && moment(currentTime).isBefore(item?.start_time)) {
            return;
        }
        if (item.defaultSeats === 0) {
            const newdata = calenderData?.timeSlots.map((data) => {
                if (data.start_time === item.start_time && data.end_time === item.end_time) {
                    return {
                        ...data,
                        defaultSeats: 6,
                    };
                }
                return data;
            }
            );
            setcalenderData({
                ...calenderData,
                timeSlots: newdata,
            });
            const newCanceledSlots = canceledSlots.filter((slot) => {
                return slot.time !== item.start_time;
            });
            setcanceledSlots(newCanceledSlots);
            removeAllState()
        } else {
            removeAllState()
            const newData = calenderData?.timeSlots.map((data) => {
                if (data.start_time === item.start_time && data.end_time === item.end_time) {
                    return {
                        ...data,
                        defaultSeats: 0,
                    };
                }
                return data;
            }
            );
            setcalenderData({
                ...calenderData,
                timeSlots: newData,
            });
            const cancelObj = {
                date,
                time: item.start_time,
            }
            setcanceledSlots([...canceledSlots, cancelObj]);
        }
    };

    const searchUser = (e) => {
        if (e.target.value === '') {
            setSearchResult([]);
            setSearch(e.target.value);
            return;
        }
        const searchUsers = users?.filter((user) => {
            return user.kanjiName.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setSearch(e.target.value);
        setSearchResult(searchUsers);
    };

    const updateSeats = async (newSeats) => {
        setMaxMember(newSeats);
        setcalenderData({
            ...calenderData,
            timeSlots: calenderData?.timeSlots.map((item) => {
                if (item.start_time === data?.start_time && item.end_time === data?.end_time) {
                    return {
                        ...item,
                        defaultSeats: newSeats,
                    };
                }
                return item;
            }),
        });
    }

    const checkIfTodayAndGreaterThanCurrentTime = (time) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const currentTime = moment().format('HH:mm');
        if (moment(date).isSameOrBefore(currentDate) && data?.start_time > currentTime) {
            return true;
        } else if (moment(date).isSame(currentDate) && data?.start_time > currentTime) {
            return true;
        } else if (moment(date).isAfter(currentDate)) {
            return true;
        }
        return false;
    }

    const checkIfPreviousDay = (date) => {
        const currentDate = moment().format('YYYY-MM-DD');
        if (moment(date).isBefore(currentDate)) {
            return true;
        }
        return false;
    }
    return (
        <>
            <div className={`flex items-start justify-start p-3 relative 
            ${openToggle ? (!data.isDefaultClose ? 'bg-[#FFFFFF]' : 'bg-[#F0F5FF]') : 'bg-[#FFFFFF]'}
            w-full`}>
                <div className='flex flex-col items-between justify-between w-full'>
                    <div className='flex flex-col items-start justify-center gap-1'>
                        <div className='flex flex-row items-center justify-between w-full'>
                            <div className='text-[#434343] font-bold text-sm'>
                                {data?.start_time}〜{data?.end_time}
                            </div>
                            {openToggle && (checkIfTodayAndGreaterThanCurrentTime(date?.start_time) && !checkIfPreviousDay(date)) && (
                                <div className='flex flex-row items-center justify-center gap-2'>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer"
                                            checked={data.defaultSeats === 0 ? false : true}
                                            onChange={() => handleToggle(data)} />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium text-[##2F54EB] dark:text-[##2F54EB]">{data.defaultSeats === 0 ? 'クローズ' : 'オープン'}</span>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='text-[#1F2937] font-light text-sm mt-4'>
                            可能会員数
                        </div>
                        <div className='text-[#1F2937] font-light text-sm mt-2'>
                            {(openToggle && (checkIfTodayAndGreaterThanCurrentTime(date?.start_time) && !checkIfPreviousDay(date))) ? (
                                data.defaultSeats !== 0 ? (
                                    <div className='flex flex-row items-center justify-between w-full h-[40px]'>
                                        <div className='flex flex-row items-center justify-center'>
                                            {currentMember + ' / '}
                                            <input
                                                onChange={(e) => {
                                                    updateSeats(e.target.value)
                                                }}
                                                type='number'
                                                value={maxMember || Number}
                                                placeholder={0}
                                                className='outline-[#ADC6FF] bg-white px-2 py-1.5 max-w-[100px] border border-[#D9D9D9] rounded-md text-[#434343] text-sm ml-2'
                                            />
                                        </div>
                                        <div
                                            onClick={() => updateSeats(currentMember)}
                                            className='flex flex-row items-center justify-end gap-2 ml-2 cursor-pointer'>
                                            <img
                                                src={Lock}
                                                alt='lock'
                                                className='w-4 h-4'
                                            />
                                            <div className='text-[#FF0000] font-light text-sm'>
                                                予約受付停止
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='w-full h-[30px] bg-[#D9D9D9] px-10 py-1.5'>
                                    </div>
                                )
                            ) : (
                                currentMember + ' / ' + maxMember
                            )}
                        </div>
                        <div className='text-[#1F2937] font-light text-sm mt-2'>
                            現在予約中の会員
                        </div>
                        {openToggle && (checkIfTodayAndGreaterThanCurrentTime(date?.start_time) && !checkIfPreviousDay(date)) ? (
                            data.defaultSeats !== 0 && (
                                <div className='grid grid-cols-1 gap-3 sm:grid-cols-3 w-full'>
                                    {data?.members.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-row items-center justify-between w-full h-[40px] mt-1 border border-[#D9D9D9] rounded-md px-2 py-1.5'>
                                                <div className='text-[#1F2937] font-light text-sm'>
                                                    {item.name}
                                                </div>
                                                <svg
                                                    onClick={() => {
                                                        handleRemoveMember(item);
                                                    }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 text-[#2F54EB] cursor-pointer"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    width='15'
                                                    height='15'
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 11-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        )
                                    })}
                                    {onClicked ? (
                                        <div className='flex flex-row items-center justify-between w-full mt-1 rounded-md'>
                                            <div className='flex flex-row items-center justify-between w-full border border-[#D9D9D9] rounded-md'>
                                                <div onClick={() => {
                                                    // const input = document.getElementById("input");
                                                    // input.focus()
                                                }} className='flex flex-row items-center justify-between w-full'>
                                                    <input
                                                        onChange={(e) => {
                                                            searchUser(e)
                                                        }}
                                                        id='input'
                                                        type='text'
                                                        value={search}
                                                        placeholder='検索'
                                                        className='outline-none bg-white px-1 py-1 max-w-[60px] rounded-md text-[#1F2937] font-light text-sm w-full'
                                                    />
                                                    <svg
                                                        onClick={() => {
                                                            setSearch('')
                                                            setOnClicked(false)
                                                            setSearchResult([])
                                                        }}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5 text-[#2F54EB] cursor-pointer"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor">
                                                        width='15'
                                                        height='15'
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 11-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            {searchResult.length > 0 && (
                                                <div
                                                    className='absolute z-10 w-1/3 h-[150px] top-1/4 right-5 bg-white border border-[#D9D9D9] rounded-md px-2 py-1.5 overflow-auto'>
                                                    {searchResult.map((name, index) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    onClick={() => {
                                                                        handleAddMember(name)
                                                                    }}
                                                                    key={index}
                                                                    className='flex flex-row items-center justify-between w-full h-[40px] mb-1 border border-[#D9D9D9] rounded-md px-2 py-1.5 cursor-pointer'>
                                                                    <div className='text-[#1F2937] font-light text-sm'>
                                                                        {name.kanjiName}
                                                                    </div>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-5 w-5 text-[#2F54EB]"
                                                                        viewBox="0 0 20 20"
                                                                        fill="currentColor">
                                                                        width='15'
                                                                        height='15'
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setOnClicked(!onClicked)
                                            }}
                                            className='flex flex-row items-center justify-center border border-[#D9D9D9] rounded-md px-1 py-1 mt-1 w-full cursor-pointer'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4 text-[#2F54EB]"
                                                viewBox="0 0 20 20"
                                                fill="currentColor">
                                                width='10'
                                                height='10'
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <div className='text-[#2F54EB] font-light text-sm'>
                                                会員追加
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        ) : (
                            <div className='flex flex-row'>
                                {data.members.map((name, index) => {
                                    return (
                                        <div key={index}>
                                            <div className='text-[#1F2937] font-light text-sm mt-2'>
                                                {name.name + '、'}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div >
            </div >
        </>
    );
}

export default CalendarEdit;
