import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../admin-components/Header';
import Delete from '../assets/delete.png';
import Modal from '../admin-components/Modal';
import useWindowDimensions from '../utils/screenDimensions'
import axios from 'axios';
import moment from 'moment';
import EditDate from '../admin-components/Edit-Date';
import Dropdown from '../admin-components/Dropdown';
import AddCoupan from '../admin-components/Add-Coupan';
import TicketView from '../admin-components/TicketView';
import BackButton from '../admin-components/BackButton';
import Toast from '../utils/Toast/Toast';

const EditUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ticketNumber, setTicketNumber] = useState(0);
    const [dropdown, setdropdown] = useState(false);
    const [dropdownValue, setdropdownValue] = useState('月4回会員');
    const [kanjiName, setKanjiName] = useState('');
    const [hiraganaName, setHiraganaName] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [user, setUser] = useState(null);
    const { width } = useWindowDimensions()
    const [approveModal, setApproveModal] = useState(false)
    const [memberShipDeleteModal, setMemberShipDeleteModal] = useState(false)
    const [memberShipUpdateModal, setMemberShipUpdateModal] = useState(false)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 3)))
    const [addcoupon, setaddcoupon] = useState(true)
    const [firstView, setFirstView] = useState(true)
    const [goBackModal, setGoBackModal] = useState(false)
    const [flashActive, setFlashActive] = useState(false)
    const [flashMessage, setFlashMessage] = useState('')
    const [flashType, setFlashType] = useState('')
    const [memberShipStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [timer] = useState(3000);

    const getUser = useCallback(async () => {
        try {
            const { data } = await axios.get(`/admin/get-user/${location?.state?.email}`)
            setUser(data.user)
            setKanjiName(data.user?.kanjiName)
            setHiraganaName(data.user?.hiraganaName)
            setdropdownValue(data.user?.memberShipType)
            setTicketNumber(data.user?.ticketCount)
            if (data.user?.memberShipType === '月4回会員' || data.user?.memberShipType === '月8回会員' || data.user?.memberShipType === '月12回会員') {
                setEndDate(new Date().setDate(new Date().getDate() + 10))
                setaddcoupon(false)
            }
        }
        catch (error) {
            // window.location.href = "/admin/login";
            console.log(error)
        }
    }, [location?.state?.email])

    const onDelete = async () => {
        try {
            await axios.delete(`/admin/delete-user/${user?.email}`)
            setDeleteModal(false)
            navigate(-1)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    useEffect(() => {
        getUser()
    }, [getUser])

    const updateMemberShipAndTickets = async () => {
        try {
            let newTicketCount
            if (addcoupon === false) {
                newTicketCount = 0
            } else {
                newTicketCount = Number(ticketNumber)
            }
            const res = await axios.put(`/admin/update-membership-and-tickets`, {
                email: user?.email,
                ticketCount: newTicketCount,
                memberShipType: dropdownValue,
                memberShipStartDate: memberShipStartDate,
                ticketExpiration: endDate,
            })
            setUser(res.data.user)
            setUpdateModal(false)
            setApproveModal(false)
            setFirstView(true)
        }
        catch (error) {
            console.log(error)
        }
    }

    const approveTickets = async () => {
        try {
            const res = await axios.put(`/admin/approve-tickets/${user?.email}`)
            setApproveModal(false)
            setUser(res.data.user)
        }
        catch (error) {
            console.log(error)
        }
    }

    const approveMembership = async () => {
        try {
            const res = await axios.put(`/admin/approve-membership/${user?.email}`, {
                ticketCount: ticketNumber,
                memberShipStartDate: memberShipStartDate,
            })
            setUser(res.data.user)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleApproval = async () => {
        if (Number(user?.unApprovedTicketCount) !== 0 && user?.unApprovedMemberShipType !== '') {
            await approveMembership()
            await approveTickets()
            setFirstView(true)
            return
        }
        if (user?.unApprovedTicketCount !== 0) {
            approveTickets()
            return
        }
        if (user?.unApprovedMemberShipType !== '') {
            approveMembership()
            return
        }
    }

    const changeDate = (e, type) => {
        if (user?.memberShipType && dropdownValue !== '回数券会員') {
            if (type === 'currentDate') {
                setCurrentDate(e)
            } else {
                setEndDate(e)
            }
        } else {
            if (type === 'currentDate') {
                setCurrentDate(e)
            } else {
                setEndDate(e)
            }
        }
    }

    const goBack = () => {
        if (user?.memberShipType !== dropdownValue || user?.ticketNumber > 0) {
            setGoBackModal(true)
            return
        }
        navigate(-1)
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleTicketNumber = (e) => {
        setTicketNumber(e.target.value)
    }

    const hideToast = () => {
        setFlashActive(false);
    };

    const handleShowToast = (e) => {
        hideToast();
        if (flashMessage) {
            setFlashActive(true);
            window.setTimeout(hideToast, timer);
        }
    };

    const handleDropdown = (e) => {
        console.log(e)
        setdropdownValue(e)
        if (e === '回数券会員') {
            setaddcoupon(true)
            setEndDate(new Date(new Date().setMonth(new Date().getMonth() + 3)))
        } else {
            setaddcoupon(false)
            setEndDate(new Date().setDate(new Date().getDate() + 10))
        }
    }

    const onErr = (errorText, messageText) => {
        setFlashMessage(messageText)
        setFlashType(errorText)
        setFlashActive(true)
        handleShowToast()
        return
    }

    return (
        <div className='w-full h-screen overflow-auto mb-10'>
            {
                flashActive && (
                    <Toast
                        type={flashType}
                        message={flashMessage}
                        duration={3000}
                        active={flashActive}
                        setActive={setFlashActive}
                        position={'tcenter'}
                        width={'small'}
                    />
                )
            }
            <Header type='management' />
            <div className='w-full h-screen'>
                <div className='p-5'>
                    <BackButton onClick={() => goBack()} />
                    {dropdownValue && (
                        <div className='bg-white p-5  border-b border-[#F0F0F0] pb-4 mb-6'>
                            <div className='border-b border-[#F0F0F0] pb-4 mb-6'>
                                <div className='
                            w-full 
                            text-[#434343]
                            text-2xl
                            '>
                                    {
                                        kanjiName ? kanjiName + ' の会員情報' : hiraganaName + ' の会員情報'
                                    }
                                </div>
                                <div className='w-full text-[#8C8C8C] mt-4'>
                                    登録日: {moment(user?.updatedAt).format('YYYY/MM/DD')}
                                </div>
                                <div
                                    onClick={() => setMemberShipDeleteModal(true)}
                                    className='flex items-center gap-2 mt-4 cursor-pointer'>
                                    <img
                                        src={Delete}
                                        alt='profile'
                                        className='w-4 h-4 rounded-full'
                                    />
                                    <div className='text-[#F5222D]'>会員を削除</div>
                                </div>
                            </div>

                            {firstView ? (
                                <div className='flex flex-col justify-start items-start mt-6'>
                                    <div className='text-[#434343]'>会員タイプ</div>
                                    <div className='flex flex-row justify-start items-center gap-4 mt-2 text-[#434343] font-bold text-2xl'>
                                        {user?.memberShipType}
                                    </div>
                                    <TicketView user={user} />
                                    <button
                                        onClick={() => {
                                            setFirstView(false)
                                        }}
                                        className={`flex items-center 
                                        text-[#2F54EB]
                                        gap-2 flex-row-reverse 
                                        bg-[#FFF]
                                        px-14 py-1 
                                        cursor-pointer
                                        border 
                                        border-[#2F54EB]
                                        mt-6`}
                                    >
                                        変更
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <div className='flex justify-start items-center gap-8 mt-6 w-full'>
                                        <Dropdown
                                            dropdownValue={dropdownValue}
                                            setdropdownValue={handleDropdown}
                                            dropdown={dropdown}
                                            setdropdown={setdropdown}
                                            width={width}
                                            user={user}
                                            onErr={onErr}
                                        />
                                    </div>

                                    <AddCoupan addcoupon={addcoupon} setaddcoupon={setaddcoupon} />

                                    {addcoupon ? (
                                        <EditDate endDate={endDate} currentDate={currentDate} changeDate={changeDate} width={width} ticketNumber={ticketNumber} setTicketNumber={setTicketNumber} handleTicketNumber={handleTicketNumber} memberShipType={user?.memberShipType} dropdownValue={dropdownValue}/>
                                    ) : null}

                                    <div className='flex justify-start items-center gap-8 mt-6'>
                                        <button
                                            disabled={!(dropdownValue !== user?.memberShipType || ticketNumber > 0)}
                                            onClick={() => {
                                                if (user?.unApprovedMemberShipType === '' || user?.unApprovedTicketCounts === 0) {
                                                    setApproveModal(true)
                                                } else {
                                                    setUpdateModal(true)
                                                }
                                            }}
                                            className={`flex items-center 
                                            ${dropdownValue !== user?.memberShipType || ticketNumber > 0 ? 'text-[#FFFFFF]' : 'text-[#8C8C8C]'}
                                            gap-2 flex-row-reverse 
                                            ${dropdownValue !== user?.memberShipType || ticketNumber > 0 ? 'bg-[#2F54EB]' : 'bg-[#F0F0F0]'}
                                            px-10 py-2 cursor-pointer`}
                                        >
                                            変更を保存
                                        </button>
                                        <button
                                            onClick={() => {
                                                goBack()
                                            }}
                                            className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse'
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                {deleteModal && (
                    <Modal
                        showModal={deleteModal}
                        setShowModal={setDeleteModal}
                        action={onDelete}
                        data={''}
                        body={`申請を削除しますか？`}
                        title='削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
                {updateModal && (
                    <Modal
                        showModal={updateModal}
                        setShowModal={setUpdateModal}
                        action={handleApproval}
                        data={''}
                        body={`申請を確認し、会員情報を更新しますか？`}
                        title='変更の確認'
                        confirmButton={'キャンセル'}
                        cancelButton={'更新'}
                    />
                )}
                {approveModal && (
                    <Modal
                        showModal={approveModal}
                        setShowModal={setApproveModal}
                        action={updateMemberShipAndTickets}
                        data={''}
                        body={`変更した会員情報を保存してもよろしいですか？`}
                        title='変更の確認'
                        confirmButton={'キャンセル'}
                        confirmButtonColor={'#2F54EB'}
                        cancelButton={'保存'}
                        cancelButtonColor={'#FFF'}
                    />
                )}
                {memberShipDeleteModal && (
                    <Modal
                        showModal={memberShipDeleteModal}
                        setShowModal={setMemberShipDeleteModal}
                        action={onDelete}
                        data={''}
                        body={`${user?.email}を削除してもよろしいですか？`}
                        title='削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
                {memberShipUpdateModal && (
                    <Modal
                        showModal={memberShipUpdateModal}
                        setShowModal={setMemberShipUpdateModal}
                        action={approveMembership}
                        data={''}
                        body={`申請を確認し、会員情報を更新しますか？`}
                        title='申請の確認'
                        confirmButton={'キャンセル'}
                        cancelButton={'更新'}
                    />
                )}
                {goBackModal && (
                    <Modal
                        showModal={goBackModal}
                        setShowModal={setGoBackModal}
                        action={handleGoBack}
                        data={''}
                        title={'内容に変更があります'}
                        body={'会員情報を変更しましたが、保存せずに前の画面へ戻りますか？'}
                        confirmButton={'編集を続ける'}
                        cancelButton={'前へ戻る'}
                    />
                )}
            </div>
        </div>
    );
}

export default EditUser;
