import React, { useState, useEffect } from 'react';
import Header from '../admin-components/Header'
import axios from 'axios'
import TicketUser from '../admin-components/Ticket-User';
import TicketStatus from '../admin-components/Ticket-User-Status';
import Modal from '../admin-components/Modal';
import Delete from '../assets/delete.png'

const UserApplication = () => {
    const [tab, settab] = useState(1)
    const [users, setusers] = useState(null)
    const [deleteButton, setdeleteButton] = useState(false)
    const [userKeys, setuserKeys] = useState(null)
    const [deleteMultipleUsersEmail, setdeleteMultipleUsersEmail] = useState(null)
    const [deleteUserEmail, setdeleteUserEmail] = useState(null)
    const [deleteMultipleModal, setdeleteMultipleModal] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [history, sethistory] = useState(null)
    const [showNotification, setshowNotification] = useState(false)

    useEffect(() => {
        getUsers()
        getHistory()
    }, [])

    const getUsers = async () => {
        try {
            const res = await axios.get('/admin/get-all-unapproved-membership-and-tickets')
            setusers(res.data.users)
            if (res.data.users.length > 0) {
                setshowNotification(true)
            }
        } catch (error) {
            window.location.href = "/admin/login";
            console.log(error.response.data.error)
        }
    }

    const getHistory = async () => {
        try {
            const res = await axios.get('/admin/get-all-histories-for-membership-and-tickets')
            sethistory(res.data.histories)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const onSelected = (e) => {
        console.log(e)
        if (e.length > 0) {
            setdeleteButton(true)
            setuserKeys(e)
            const emails = users.filter((user) => {
                return e.includes(user.key)
            }
            )
            setdeleteMultipleUsersEmail(emails)
        } else {
            setdeleteButton(false)
        }
    }

    const onDeletePressed = (item) => {
        setdeleteUserEmail(item)
        setshowModal(true)
    }

    const onDeleteMultiplePressed = async () => {
        try {
            await axios.put(`/admin/reject-membership-and-tickets/${userKeys}`)
            setdeleteMultipleModal(false)
            setdeleteButton(false)
            getUsers()
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const onDelete = async () => {
        try {
            await axios.put(`/admin/reject-membership-and-tickets-for-user/${deleteUserEmail}`)
            setshowModal(false)
            getUsers()
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    return (
        <div className='w-full h-screen mb-10'>

            <>
                <Header type='application' notification={showNotification} />
                <div className='bg-white p-5 pb-0'>
                    <div className='text-[30px] font-bold'>申請通知</div>
                    <div className='flex gap-7 mt-6 whitespace-nowrap  overflow-x-auto'>
                        <button
                            onClick={() => {
                                settab(1)
                            }}
                            className={`pb-2 ${tab === 1
                                ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                : 'text-[#434343]'
                                }`}
                        >
                            申請一覧
                        </button>
                        <button
                            onClick={() => {
                                settab(2)
                            }}
                            className={`pb-2 ${tab === 2
                                ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                : 'text-[#434343]'
                                }`}
                        >
                            変更履歴
                        </button>
                    </div>
                </div>
                <div className='p-5'>
                    <div className='bg-white p-5'>
                        {tab === 1 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        申請一覧
                                    </div>
                                </div>

                                {deleteButton && (
                                    <div
                                        onClick={() => setdeleteMultipleModal(true)}
                                        className='flex gap-2 items-center justify-start px-2 py-1 cursor-pointer'>
                                        <img
                                            src={Delete}
                                            alt='delete'
                                            className='w-4 h-4 text-red-600'
                                        />
                                        <div className='text-red-600 text-[16px]'>
                                            削除
                                        </div>
                                    </div>
                                )}
                                <div className='overflow-auto overflow-x-auto'>
                                    <TicketUser users={users} callback={onSelected} onDeletePressed={onDeletePressed} />
                                </div>
                            </>
                        ) : tab === 2 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        変更履歴
                                    </div>
                                </div>
                                <div className='overflow-auto overflow-x-auto'>
                                    <TicketStatus data={history} />
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
                {showModal && (
                    <Modal
                        showModal={showModal}
                        setShowModal={setshowModal}
                        action={onDelete}
                        data={deleteUserEmail}
                        body={`を削除してもよろしいですか？`}
                        title='削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
                {deleteMultipleModal && (
                    <Modal
                        showModal={deleteMultipleModal}
                        setShowModal={setdeleteMultipleModal}
                        action={onDeleteMultiplePressed}
                        data={deleteMultipleUsersEmail}
                        body={`を削除してもよろしいですか？`}
                        title='削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
            </>
        </div>
    );
}

export default UserApplication;
