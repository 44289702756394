import React, { useState, useEffect } from 'react';
import Header from '../admin-components/Header'
import Search from '../assets/search.png'
import UserTable from '../admin-components/User-Table';
import axios from 'axios'
import Delete from '../assets/delete.png'
import Mail from '../assets/mail.png'
import { Link, useLocation } from 'react-router-dom'
import UserApplied from '../admin-components/User-Applied';
import InvitedUsers from '../admin-components/Invited-Users';
import UserStatus from '../admin-components/User-Status';
import useWindowDimensions from '../utils/screenDimensions';
import Modal from '../admin-components/Modal';

const AccountManagmentView = () => {
    const location = useLocation()
    const { width } = useWindowDimensions()
    const [tab, settab] = useState(location?.state?.tab || 1)
    useEffect(() => {
        window.history.replaceState(null, '', window.location.pathname);
    }, [location])
    const [users, setusers] = useState([])
    const [email, setemail] = useState('')
    const [deleteButton, setdeleteButton] = useState(false)
    const [invitedUsers, setinvitedUsers] = useState([])
    const [applicationUser, setapplicationUser] = useState([])
    const [modal, setshowModal] = useState(false)
    const [deleteUserEmail, setdeleteUserEmail] = useState('')
    const [deleteMultiple, setdeleteMultiple] = useState(false)
    const [deleteMultipleUsersKey, setdeleteMultipleUsers] = useState([])
    const [deleteMultipleUsersEmail, setdeleteMultipleUsersEmail] = useState([])
    const [sendEmail, setsendEmail] = useState(false)
    const [sendEmailMultiple, setsendEmailMultiple] = useState(false)
    const [usersHistories, setusersHistories] = useState([])
    const [isApplication, setisApplication] = useState(false)

    useEffect(() => {
        getUsers()
        getInvitedUsers()
        getApplicationUsers()
        getusersHistories()
    }, [])

    const getUsers = async () => {
        try {
            const res = await axios.get('/admin/users')
            setusers(res.data.users)
        } catch (error) {
            window.location.href = "/admin/login";
            console.log(error.response.data.error)
        }
    }

    const getInvitedUsers = async () => {
        try {
            const res = await axios.get('/admin/get-inited-users')
            setinvitedUsers(res.data.users)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const getApplicationUsers = async () => {
        try {
            const res = await axios.get('/admin/get-applications-users')
            setapplicationUser(res.data.users)
            if (res.data.users.length > 0) {
                setisApplication(true)
            }
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const getusersHistories = async () => {
        try {
            const res = await axios.get('/admin/get-users-histories')
            setusersHistories(res.data.histories)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const onSelected = (e) => {
        if (e.length > 0) {
            if (tab === 1) {
                setdeleteMultipleUsers(e)
                setdeleteButton(true)
                const emails = users.filter((user) => {
                    return e.includes(user.key)
                }
                )
                setdeleteMultipleUsersEmail(emails)
            } else if (tab === 2) {
                setdeleteMultipleUsers(e)
                setdeleteButton(true)
                const emails = applicationUser.filter((user) => {
                    return e.includes(user.key)
                }
                )
                setdeleteMultipleUsersEmail(emails)
            } else if (tab === 3) {
                setdeleteMultipleUsers(e)
                setdeleteButton(true)
                const emails = invitedUsers.filter((user) => {
                    return e.includes(user.key)
                }
                )
                setdeleteMultipleUsersEmail(emails)
            }
        } else {
            setdeleteButton(false)
        }
    }

    const onSendEmailMultiple = (e) => {
        if (e.length > 0) {
            setdeleteMultipleUsers(e)
            const emails = invitedUsers.filter((user) => {
                return e.includes(user.key)
            })
            setdeleteMultipleUsersEmail(emails)
        } else {
            setdeleteButton(false)
        }
    }

    const searchUser = () => {
        if (email?.length === 0) {
            getUsers()
            getInvitedUsers()
            getApplicationUsers()
            getusersHistories()
        }
        if (tab === 1) {
            const search = users.filter((user) => {
                return user.email.toLowerCase().includes(email.toLowerCase()) || user.kanjiName.toLowerCase().includes(email.toLowerCase())
            })
            setusers(search)
        } else if (tab === 2) {
            const search = applicationUser.filter((user) => {
                return user.email.toLowerCase().includes(email.toLowerCase()) || user.kanjiName.toLowerCase().includes(email.toLowerCase())
            })
            setapplicationUser(search)
        } else if (tab === 3) {
            const search = invitedUsers.filter((user) => {
                return user.email.toLowerCase().includes(email.toLowerCase()) || user.kanjiName.toLowerCase().includes(email.toLowerCase())
            })
            setinvitedUsers(search)
        } else if (tab === 4) {
            const search = usersHistories.filter((user) => {
                return user?.user?.email?.toLowerCase().includes(email.toLowerCase()) || user?.user?.kanjiName?.toLowerCase().includes(email.toLowerCase())
            })
            setusersHistories(search)
        }
    }

    const onDeletePressed = (item) => {
        setdeleteUserEmail(item)
        setshowModal(true)
    }

    const onSendEmail = (item) => {
        setsendEmail(true)
        setdeleteUserEmail(item)
    }

    const onDelete = async () => {
        try {
            await axios.delete(`/admin/delete-user/${deleteUserEmail}`)
            setshowModal(false)
            getUsers()
            getInvitedUsers()
            getApplicationUsers()
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const deleteMultipleUsers = async () => {
        try {
            await axios.delete(`/admin/delete-users/${deleteMultipleUsersKey}`)
            setdeleteMultiple(false)
            setdeleteButton(false)
            getUsers()
            getInvitedUsers()
            getApplicationUsers()
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const sendMailToUser = async (email) => {
        try {
            await axios.post(`/admin/send-email/${email}`)
            setsendEmail(false)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    const sendMailToMultipleUsers = async () => {
        try {
            console.log(deleteMultipleUsersKey)
            await axios.post(`/admin/send-emails/${deleteMultipleUsersKey}`)
            setsendEmailMultiple(false)
        } catch (error) {
            console.log(error.response.data.error)
        }
    }

    return (
        <div className='w-full h-screen mb-10'>

            <>
                <Header type='management' />
                <div className='bg-white p-5 pb-0'>
                    <div className='text-[30px] font-bold'>会員管理</div>
                    {width > 768 ? (
                        <div className='flex gap-2 mt-6 justify-center items-center w-full'>
                            <div className='flex items-center gap-4 border-[#ADC6FF] border px-6'>
                                <img
                                    src={Search}
                                    alt='search'
                                    className='w-4 h-4'
                                />
                                <input
                                    type='text'
                                    placeholder='お名前またはメールアドレスで検索する'
                                    className='w-[300px] h-[40px] border-[#E5E5E5] rounded-lg py-2 px-1.5 outline-none'
                                    onChange={(e) => {
                                        setemail(e.target.value)
                                    }}
                                />
                            </div>
                            <button
                                className='bg-[#2F54EB] text-white px-8 py-2'
                                onClick={() => {
                                    searchUser()
                                }}
                            >
                                会員検索
                            </button>
                        </div>
                    ) : (
                        <div className='flex flex-col gap-2 mt-6 justify-center items-center w-full'>
                            <div className='flex items-center gap-4 border-[#ADC6FF] border px-3'>
                                <img
                                    src={Search}
                                    alt='search'
                                    className='w-4 h-4'
                                />
                                <input
                                    type='text'
                                    placeholder='お名前またはメールアドレスで検索する'
                                    className='w-[300px] h-[40px] border-[#E5E5E5] rounded-lg py-2 px-1.5 outline-none'
                                    onChange={(e) => {
                                        searchUser(e.target.value)
                                        setemail(e.target.value)
                                    }}
                                />
                            </div>
                            <button
                                className='bg-[#2F54EB] text-white px-8 py-2'
                                onClick={() => {
                                    searchUser()
                                }}
                            >
                                会員検索
                            </button>
                        </div>
                    )}
                    <div className='flex gap-7 mt-6 whitespace-nowrap  overflow-x-auto'>
                        <button
                            onClick={() => {
                                settab(1)
                            }}
                            className={`pb-2 ${tab === 1
                                ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                : 'text-[#434343]'
                                }`}
                        >
                            会員一覧
                        </button>
                        <>
                            <button
                                onClick={() => {
                                    settab(2)
                                }}
                                className={`pb-2 ${tab === 2
                                    ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                    : 'text-[#434343]'
                                    }`}
                            >
                                登録申請中
                            </button>
                            {isApplication > 0 && (
                                <div className='text-red-600 flex justify-center items-center -ml-8 -mt-4'>
                                    *
                                </div>
                            )}
                        </>
                        <button
                            onClick={() => {
                                settab(3)
                            }}
                            className={`pb-2 ${tab === 3
                                ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                : 'text-[#434343]'
                                }`}
                        >
                            招待中
                        </button>
                        <button
                            onClick={() => {
                                settab(4)
                            }}
                            className={`pb-2 ${tab === 4
                                ? 'text-[#2F54EB] border-b-2 border-[#2F54EB]'
                                : 'text-[#434343]'
                                }`}
                        >
                            変更履歴
                        </button>
                    </div>
                </div>
                <div className='p-5'>
                    <div className='bg-white p-5'>
                        {tab === 1 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        会員一覧
                                    </div>
                                </div>

                                <div className={`
                                flex mb-6 w-full 
                                ${deleteButton ? 'justify-between' : 'justify-end'}
                                ${deleteButton ? 'items-center' : 'items-end'}
                                `
                                }>
                                    {deleteButton && (
                                        <div
                                            onClick={() => setdeleteMultiple(true)}
                                            className='flex gap-2 items-center justify-start px-2 py-1 cursor-pointer'>
                                            <img
                                                src={Delete}
                                                alt='delete'
                                                className='w-4 h-4 text-red-600'
                                            />
                                            <div className='text-red-600 text-[16px]'>
                                                削除
                                            </div>
                                        </div>
                                    )}
                                    <Link
                                        to='/admin/invite-user'
                                        className='flex gap-2 items-end justify-end  bg-blue-600 px-8 py-1 cursor-pointer'>
                                        <div className='text-[#FFFFFF] text-[16px]'>
                                            新規会員を追加
                                        </div>
                                    </Link>
                                </div>

                                <div className='overflow-auto overflow-x-auto'>
                                    <UserTable users={users} callback={onSelected} onDelete={onDeletePressed} />
                                </div>
                            </>
                        ) : tab === 2 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        登録申請中
                                    </div>
                                </div>
                                <div className={`
                                flex mb-6 w-full 
                                ${deleteButton ? 'justify-between' : 'justify-end'}
                                ${deleteButton ? 'items-center' : 'items-end'}
                                `
                                }>
                                    {deleteButton && (
                                        <div
                                            onClick={() => setdeleteMultiple(true)}
                                            className='flex gap-2 items-center justify-start px-2 py-1 cursor-pointer'>
                                            <img
                                                src={Delete}
                                                alt='delete'
                                                className='w-4 h-4 text-red-600'
                                            />
                                            <div className='text-red-600 text-[16px]'>
                                                申請を削除
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='w-full overflow-auto overflow-x-auto'>
                                    <UserApplied users={applicationUser} callback={onSelected} onDelete={onDeletePressed} />
                                </div>
                            </>
                        ) : tab === 3 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        招待中
                                    </div>
                                </div>
                                <div className={`
                                flex mb-6 w-full 
                                ${deleteButton ? 'justify-between' : 'justify-end'}
                                ${deleteButton ? 'items-center' : 'items-end'}
                                `
                                }>
                                    {deleteButton && (
                                        <div
                                            className='flex gap-2 items-center justify-start py-1 cursor-pointer'>
                                            <div
                                                onClick={() => setdeleteMultiple(true)}
                                                className='flex gap-2 items-center justify-start px-2 py-1 cursor-pointer'>
                                                <img
                                                    src={Delete}
                                                    alt='delete'
                                                    className='w-4 h-4 text-red-600'
                                                />
                                                <div className='text-red-600 text-[16px]'>
                                                    招待をキャンセル
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setsendEmailMultiple(true)
                                                }}
                                                className='flex gap-2 items-center justify-start px-2 py-1 cursor-pointer'>
                                                <img
                                                    src={Mail}
                                                    alt='send mail'
                                                    className='w-4 h-4 text-blue-600'
                                                />
                                                <div className='text-blue-600 text-[16px]'>
                                                    再送信
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    <Link
                                        to='/admin/invite-user'
                                        className='flex gap-2 items-end justify-end  bg-blue-600 px-8 py-1 cursor-pointer'>
                                        <div className='text-[#FFFFFF] text-[16px]'>
                                            新規会員を追加
                                        </div>
                                    </Link>
                                </div>
                                <div className='overflow-auto overflow-x-auto'>
                                    <InvitedUsers users={invitedUsers} callback={onSelected} onDelete={onDeletePressed} onSendEmailMultiple={onSendEmailMultiple} onSendEmail={onSendEmail} />
                                </div>
                            </>
                        ) : tab === 4 ? (
                            <>
                                <div className='w-full border-b border-[#F0F0F0] pb-4 mb-6'>
                                    <div className='text-[#434343] text-[20px] mb-0.5'>
                                        変更履歴
                                    </div>
                                </div>
                                <div className='overflow-auto overflow-x-auto'>
                                    <UserStatus data={usersHistories} />
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
                {modal && (
                    <Modal
                        showModal={modal}
                        setShowModal={setshowModal}
                        action={onDelete}
                        data={deleteUserEmail}
                        body={`を削除してもよろしいですか？`}
                        title='削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
                {deleteMultiple && (
                    <Modal
                        showModal={deleteMultiple}
                        setShowModal={setdeleteMultiple}
                        action={deleteMultipleUsers}
                        data={deleteMultipleUsersEmail}
                        body={`を削除してもよろしいですか？`}
                        title=' 削除の確認'
                        confirmButton={'いいえ'}
                        cancelButton={'削除'}
                    />
                )}
                {sendEmail && (
                    <Modal
                        showModal={sendEmail}
                        setShowModal={setsendEmail}
                        action={sendMailToUser}
                        data={deleteUserEmail}
                        body={`招待メールを再送信してもよろしいですか？`}
                        title='招待の再送信'
                        confirmButton={'いいえ'}
                        cancelButton={'再送信'}
                    />
                )}
                {sendEmailMultiple && (
                    <Modal
                        showModal={sendEmailMultiple}
                        setShowModal={setsendEmailMultiple}
                        action={sendMailToMultipleUsers}
                        data={deleteMultipleUsersEmail}
                        body={`招待メールを再送信してもよろしいですか？`}
                        title='招待の再送信'
                        confirmButton={'いいえ'}
                        cancelButton={'再送信'}
                    />
                )}
            </>
        </div>
    );
}

export default AccountManagmentView;
