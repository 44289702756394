import React from 'react';

const CalendarDropdown = ({ dayFormat, setdropdown, dropdown, openCalendar, toggleCalendar, day, setOpenCalendar }) => {
    return (
        <div>
            <div onClick={() => {
                setdropdown(!dropdown)
                setOpenCalendar(day?.date)

            }}
                className={`relative cursor-pointer flex gap-3 mb-2
                            w-full
                            justify-center
                            items-center bg-white text-[#141414] rounded-[2px] px-2`}>
                <div className='flex flex-row mt-2 justify-center items-center text-[#2F54EB] cursor-pointer'>
                    {dayFormat}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-1"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <div className={`absolute justify-center items-center text-[#2F54EB] top-full left-0 w-full max-h-[300px] h-fit overflow-y-auto p-2 bg-white border border-[#ADC6FF] rounded-[4px] 
                ${dropdown && openCalendar === day?.date ? 'block' : 'hidden'}
                `}>

                    <div onClick={() => { toggleCalendar(day?.date, "平日時間") }} className='flex justify-center items-center text-[#2F54EB] h-[30px] w-full'>平日時間</div>
                    <div onClick={() => { toggleCalendar(day?.date, "土曜時間") }} className='flex justify-center items-center text-[#2F54EB] h-[30px] w-full'>土曜時間</div>
                    <div onClick={() => { toggleCalendar(day?.date, "日曜・祝日時間") }} className='flex justify-center items-center text-[#2F54EB] h-[30px] w-full'>日曜・祝日時間</div>

                </div>
            </div>
        </div>
    );
}

export default CalendarDropdown;
