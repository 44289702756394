import React, { useState } from 'react'
import Lock from '../assets/lock.png'
import moment from 'moment'

const CalendarSchedule = ({ isToggle, todayDate, start_time, people, totalPeople, calendar, setcalendar, defaultSeats }) => {

    const [totalUsers, setTotalUsers] = useState(defaultSeats || 0)
    const [currentDateAndTime] = useState(moment().format('YYYY-MM-DD HH:mm'))

    const handleToggle = (newDate) => {
        if (!isToggle) {
            return
        } else {
            if (defaultSeats !== 0) {
                const newCalendar = calendar.map((date) => {
                    if (date.date === newDate) {
                        const newTimeSlots = date.timeSlots.map((timeSlot) => {
                            if (timeSlot.start_time === start_time) {
                                return {
                                    ...timeSlot,
                                    defaultSeats: 0
                                }
                            }
                            return timeSlot
                        })
                        return {
                            ...date,
                            timeSlots: newTimeSlots
                        }
                    }
                    return date
                })
                setcalendar(newCalendar)
            } else {
                const newCalendar = calendar.map((date) => {
                    if (date.date === newDate) {
                        const newTimeSlots = date.timeSlots.map((timeSlot) => {
                            if (timeSlot.start_time === start_time) {
                                return {
                                    ...timeSlot,
                                    defaultSeats: 6
                                }
                            }
                            return timeSlot
                        })
                        return {
                            ...date,
                            timeSlots: newTimeSlots
                        }
                    }
                    return date
                }
                )
                setcalendar(newCalendar)
            }
        }
    }

    const handleCallback = (e) => {
        setTotalUsers(e.target.value)
        const newCalendar = calendar.map((date) => {
            if (date.date === todayDate) {
                const newTimeSlots = date.timeSlots.map((timeSlot) => {
                    if (timeSlot.start_time === start_time) {
                        return {
                            ...timeSlot,
                            defaultSeats: e.target.value
                        }
                    }
                    return timeSlot
                })
                return {
                    ...date,
                    timeSlots: newTimeSlots
                }
            }
            return date
        })
        setcalendar(newCalendar)
    }

    return (
        <>
            <div className={`flex flex-col items-center justify-center min-h-[200px] text-center p-3 
            ${currentDateAndTime > todayDate + ' ' + start_time ? 'bg-[#FFF]' : 'bg-[#F0F5FF]'}
            border border-[#ADC6FF]`}>
                <p className='text-[#434343] font-medium'>{start_time}</p>
                {(defaultSeats !== 0) && currentDateAndTime < todayDate + ' ' + start_time ? (
                    <>
                        <div className='flex items-center justify-center flex-row mt-2'>
                            <div className='flex flex-row items-center justify-center gap-3'>
                                <p className='text-[#434343] font-medium'>会員数</p>
                                <input
                                    type='number'
                                    value={totalUsers}
                                    className='w-16 h-8 border border-gray-300 p-2 rounded-md'
                                    onChange={(e) => {
                                        handleCallback(e)
                                    }}
                                />
                            </div>
                        </div>
                        {currentDateAndTime < (todayDate + ' ' + start_time) && (
                            <div
                            className='flex justify-center items-center mb-2 mt-4'>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer"
                                    onClick={() => {
                                        handleToggle(todayDate)
                                    }}
                                    onChange={() => { }}
                                    checked={isToggle && (currentDateAndTime > todayDate + ' ' + start_time || defaultSeats !== 0)}
                                />
                                <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full 
                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                ${isToggle && (currentDateAndTime < todayDate + ' ' + start_time || defaultSeats !== 0) ? 'peer-checked:bg-blue-600' : 'peer-checked:bg-gray-300'}
                `
                                }></div>
                            </label>
                        </div>
                        )}
                    </>
                ) : (
                    totalPeople > 0 ?
                        <>
                            <div className='flex items-center justify-center mt-4 '>
                                {Array.apply(null, Array(Number(totalPeople))).map((_, i) => (
                                    <div
                                        key={i}
                                        className={`w-4 h-4 rounded-full 
                                        ${people?.length > i ? 'bg-[#000]' : 'bg-[#FFF]'}
                                        ${people?.length > i ? '' : 'border border-[#000]'
                                            }
                                        `
                                        }
                                    />
                                ))}
                            </div>
                        </>
                        :
                        <>
                            <img
                                src={Lock}
                                alt='lock'
                                className='w-4 h-4 mt-2'
                            />
                            {currentDateAndTime < (todayDate + ' ' + start_time) && (
                                <div
                                className='flex justify-center items-center mb-2 mt-4'>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value="" className="sr-only peer"
                                        onClick={() => {
                                            handleToggle(todayDate)
                                        }}
                                        onChange={() => { }}
                                        checked={isToggle && (currentDateAndTime > todayDate + ' ' + start_time || defaultSeats !== 0)}
                                    />
                                    <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full 
                peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                ${isToggle && (currentDateAndTime < todayDate + ' ' + start_time || defaultSeats !== 0) ? 'peer-checked:bg-blue-600' : 'peer-checked:bg-gray-300'}
                `
                                    }></div>
                                </label>
                            </div>
                            )}
                        </>
                    // <div className={`flex items-center justify-center  p-3 'bg-[#FFF]`}>
                    // </div>
                )}
            </div>
        </>
    )
}

export default CalendarSchedule
