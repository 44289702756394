import React, { useState, useEffect, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../admin-components/Header';
import Delete from '../assets/delete.png';
import useWindowDimensions from '../utils/screenDimensions';
import axios from 'axios';
import moment from 'moment';
import EditDate from '../admin-components/Edit-Date';
import Dropdown from '../admin-components/Dropdown';
import TextField from '../admin-components/Text-Field';
import Modal from '../admin-components/Modal';
import BackButton from '../admin-components/BackButton';

const AppliedUserAction = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ticketNumber, setTicketNumber] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const [kanjiName, setKanjiName] = useState('');
    const [hiraganaName, setHiraganaName] = useState('');
    const [email, setEmail] = useState('');
    const [user, setUser] = useState(null);
    const { width } = useWindowDimensions();
    const [modal, setShowModal] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('月4回会員');
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(3, 'months').format('YYYY-MM-DD'));

    useEffect(() => {
        if (location?.state?.email) {
            const init = async () => {
                const { data } = await axios.get(`/admin/get-user/${location?.state?.email}`);
                setUser(data.user);
                setKanjiName(data.user?.kanjiName);
                setHiraganaName(data.user?.hiraganaName);
                setEmail(data.user?.email);
                setDropdownValue(data.user?.memberShipType || '月4回会員');
            };
            init();
        }
    }, [location?.state?.email]);

    const approveUser = async () => {
        try {
            if (dropdownValue === '回数券会員') {
                await axios.put(`/admin/approve-user/${location?.state?.email}/${ticketNumber}/${currentDate}`, {
                    memberShipType: dropdownValue,
                    kanjiName,
                    hiraganaName,
                });
                navigate(`/admin/account-management`, {
                    state: {
                        tab: 2,
                    },
                });
            } else {
                await axios.put(`/admin/approve-user/${location?.state?.email}`, {
                    memberShipType: dropdownValue,
                    kanjiName,
                    hiraganaName,
                });
                navigate(`/admin/account-management`, {
                    state: {
                        tab: 2,
                    },
                });
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const onDelete = async () => {
        try {
            await axios.delete(`/admin/delete-user/${location?.state?.email}`);
            setShowModal(false);
            navigate(`/admin/account-management`, {
                state: {
                    tab: 2,
                },
            });
        } catch (error) {
            console.warn(error.response.data.error);
        }
    };

    const changeDate = (e, type) => {
        const newDate = moment(e.target.value);
        if (!newDate.isValid()) {
            console.warn('Invalid date:', e.target.value);
            return;
        }
        if (type === 'currentDate') {
            setCurrentDate(newDate.format('YYYY-MM-DD'));
            setEndDate(newDate.add(3, 'months').format('YYYY-MM-DD'));
        } else {
            setCurrentDate(newDate.subtract(3, 'months').format('YYYY-MM-DD'));
            setEndDate(newDate.format('YYYY-MM-DD'));
        }
    };

    const handleTicketNumber = (e) => {
        setTicketNumber(e.target.value);
    };

    return (
        <div className='w-full h-screen mb-10'>
            <Header type='management' />
            <div className='w-full h-screen'>
                <div className='p-5'>
                    <BackButton onClick={() => navigate(-1)} />
                    <div className='bg-white p-5 border-b border-[#F0F0F0] pb-4 mb-6'>
                        <div className='border-b border-[#F0F0F0] pb-4 mb-6'>
                            <div className='w-full text-[#434343]'>
                                {user?.kanjiName}の申請詳細
                            </div>
                            <div
                                onClick={() => setShowModal(true)}
                                className='flex items-center gap-2 mt-4 cursor-pointer'>
                                <img
                                    src={Delete}
                                    alt='profile'
                                    className='w-4 h-4 rounded-full'
                                />
                                <div className='text-[#F5222D]'>申請を削除</div>
                            </div>
                        </div>

                        <div className='flex items-center gap-2 mb-2'>
                            <div className='text-[#434343]'>会員を下記のように予約システムに登録してもよろしいですか。</div>
                        </div>

                        <TextField input={kanjiName} type={'text'} setInput={setKanjiName} label='お名前（漢字）' placeholder='お名前' width={width} />

                        <TextField input={hiraganaName} type={'text'} setInput={setHiraganaName} label='お名前（ひらがな）' placeholder='おなまえ' width={width} />

                        <TextField input={email} setInput={setEmail} type={'text'} label='登録用のメールアドレス' placeholder='メールアドレス' width={width} />


                        <div className='flex items-center gap-2 mb-2 mt-6'>
                            <Dropdown dropdownValue={dropdownValue} setDropdownValue={setDropdownValue} dropdown={dropdown} setDropdown={setDropdown} width={width} />
                        </div>

                        {dropdownValue === "回数券会員" ? (
                            <EditDate endDate={endDate} currentDate={currentDate} changeDate={changeDate} width={width} ticketNumber={ticketNumber} setTicketNumber={setTicketNumber} handleTicketNumber={handleTicketNumber} />
                        ) : null}

                        <div className='flex justify-start items-center gap-8 mt-6'>
                            <button
                                disabled={(kanjiName !== '' || email !== '' || hiraganaName !== '' || dropdownValue !== '' || (dropdownValue === '回数券会員' && ticketNumber === (ticketNumber > 0))) ? false : true}
                                onClick={() => {
                                    approveUser();
                                }}
                                className={`flex items-center gap-2 flex-row-reverse 
                                ${kanjiName === '' || email === '' || hiraganaName === '' || dropdownValue === '' || (dropdownValue === '回数券会員' && !ticketNumber) ? 'bg-[#D9D9D9]' : 'bg-[#2F54EB]'}
                                ${kanjiName === '' || email === '' || hiraganaName === '' || dropdownValue === '' ? 'text-white' : 'text-white'}
                                px-10 py-2`}
                            >
                                登録を承認
                            </button>
                            <button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                className='flex items-center text-[#2F54EB] gap-2 flex-row-reverse'
                            >
                                キャンセル
                            </button>
                        </div>
                    </div>
                </div>
                {modal && (
                    <Modal
                        showModal={modal}
                        setShowModal={setShowModal}
                        action={onDelete}
                        data={kanjiName !== '' ? kanjiName : email}
                        body='を削除してもよろしいですか？'
                        title='削除の確認'
                        confirmButton='いいえ'
                        cancelButton='削除'
                    />
                )}
            </div>
        </div>
    );
};

export default memo(AppliedUserAction);