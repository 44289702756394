import React from 'react'
import Lock from '../assets/lock.png'

const CalendarLock = ({ start_time, people, totalPeople }) => {
    return (
        <>
            <div className={`flex items-center justify-center min-h-[200px] text-center p-3 
            ${totalPeople > 0 ? 'bg-[#FFF]' : 'bg-[#FFF]'}
            border border-[#ADC6FF]`}>
                <div className='flex flex-col items-center justify-center gap-5'>
                    <p className='text-[#434343] font-medium'>{start_time}</p>
                    {totalPeople > 0 ?
                        <>
                            <div className='flex items-center justify-center '>
                                {Array.apply(null, Array(Number(totalPeople))).map((_, i) => (
                                    <div
                                        key={i}
                                        className={`w-4 h-4 rounded-full 
                                        ${people?.length > i ? 'bg-[#000]' : 'bg-[#FFF]'}
                                        ${people?.length > i ? '' : 'border border-[#000]'
                                            }
                                        `
                                        }
                                    />
                                ))}
                            </div>
                        </>
                        :
                        <img
                            src={Lock}
                            alt='lock'
                            className='w-4 h-4'
                        />
                        // <div className={`flex items-center justify-center  p-3 'bg-[#FFF]`}>
                        // </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CalendarLock
