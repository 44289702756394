import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
    const [admin, setAdmin] = useState(null);
    const [users, setUsers] = useState(null);
    const [showNotification, setshowNotification] = useState(false)
    const [calendarHistories, setCalendarHistories] = useState(null)
    const [week, setweek] = useState(0);

    useEffect(() => {
        async function getUser() {
            try {
                const res = await axios.get("/admin/profile");
                setAdmin(res.data.user);
            } catch (err) {
                console.warn(err.response.data);
            }
        }
        getUser();
        getCalendarHistories()
    }, []);

    useEffect(() => {
        getUsers()
    }, [])

    const getAllUsers = async () => {
        try {
            const res = await axios.get("/admin/users");
            setUsers(res.data.users);
        } catch (err) {
            window.location.href = "/admin/login";
            return 0;
        }
    }

    const getUsers = async () => {
        try {
            const res = await axios.get('/admin/get-all-unapproved-membership-and-tickets')
            if (res.data.users.length > 0) {
                setshowNotification(true)
            }
        } catch (error) {
            console.warn(error.response.data.error)
        }
    }

    const getCalendarHistories = async () => {
        try {
            const res = await axios.get('/admin/get-calendar-histories')
            setCalendarHistories(res.data.histories)
        } catch (error) {
            console.warn(error.response.data.error)
        }
    }

    return (
        <AdminContext.Provider value={
            {
                admin,
                setAdmin,
                users,
                setUsers,
                showNotification,
                setshowNotification,
                getAllUsers,
                calendarHistories,
                setCalendarHistories,
                getCalendarHistories,
                week,
                setweek,
            }
        }>
            {children}
        </AdminContext.Provider>
    );
}